import { createApp } from 'vue'
import SideWidget from './SideWidget.vue'
import axios from 'axios'

import UserManagement from './plugins/user_management'
import CartPlugin from './plugins/cart'
import DatePlugin from './plugins/date_manipolation'
import FormPlugin from './plugins/form_functions'
import RequestsPlugin from './plugins/downloader'
import StoragePlugin from './plugins/storage'
import TranslationPlugin from './plugins/translation'


export default class ImproovingWidget {
	constructor(BearerToken, SchoolID, Options = null, TestEnvironment = false) {
		this.SchoolID = SchoolID
		this.TestEnvironment = TestEnvironment
		this.setBearerToken(BearerToken)
		this.initializeBox(Options)
		this.initializeListeners()
	}

	open = () => {
		this.widgetBox.classList.add('widget-box-shown')
	}
	openSection = (section, categoryId = null, filterName = null) => {
		this.open();
		document.dispatchEvent(new CustomEvent(['show', 'section'].join('-'), {detail: {section: section.trim(), category_id: categoryId !== null ? categoryId.toString().trim() : categoryId, filter_name: filterName !== null ? filterName.toString().toLowerCase().trim() : filterName}}));
	}
	openProduct = (productType, productId) => {
		this.open();
		document.dispatchEvent(new CustomEvent(['show', 'product'].join('-'), {detail: {productType: productType.toString().trim(), productId: productId.toString().trim()}}));
	}
	close = () => {
		this.widgetBox.classList.remove('widget-box-shown')
	}

	setBearerToken = (token) => {
		this.BearerToken = token
		this.initializeAxios()
	}

	initializeAxios = () => {
		axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
		axios.defaults.headers.common["BearerToken"] = this.BearerToken
		axios.defaults.headers.common["Authorization"] = "Bearer " + this.BearerToken
		axios.defaults.headers.common["Accept"] = "application/json"
	}

	initializeBox = (Options) => {
		this.widgetBox = document.createElement('div')
		this.widgetBox.id = 'improoving-widget'

		document.body.appendChild(this.widgetBox)

		this.initializeVue(Options)
	}

	initializeVue = (Options) => {
		let allowedSections = [];
		['packet', 'event', 'moment', 'lesson', 'teacher', 'giftCard'].forEach(section => {
			if(Options === null || typeof Options.sections === 'undefined' || Options.sections === null || typeof Options.sections[section] === 'undefined' || Options.sections[section] !== false)
				allowedSections.push(section);
		});

		let widgetOptions = {
			schoolId: this.SchoolID,
			allowedSections: allowedSections,
			allowTeacherSelection: Options !== null && typeof Options.allowTeacherSelection !== 'undefined' ? Options.allowTeacherSelection : true,
			blockedCategories: Options !== null && typeof Options.blockedCategories !== 'undefined' && Options.blockedCategories instanceof Array ? Options.blockedCategories : [],
			offsetFromDate: Options !== null && typeof Options.offsetFromDate !== 'undefined' ? Options.offsetFromDate : 1,
			offsetToDate: Options !== null && typeof Options.offsetToDate !== 'undefined' ? Options.offsetToDate : null,
			allowLessonWithoutAvailability: Options !== null && typeof Options.allowLessonWithoutAvailability !== 'undefined' ? Options.allowLessonWithoutAvailability : true,
			showPriceOfIncludedServices: Options !== null && typeof Options.showPriceOfIncludedServices !== 'undefined' ? Options.showPriceOfIncludedServices : false
		}

		const VueApp = createApp(SideWidget, widgetOptions)

		VueApp.config.unwrapInjectedRef = true

		// They are loaded in order
		VueApp.use(StoragePlugin)
		VueApp.use(DatePlugin)
		VueApp.use(RequestsPlugin, this.SchoolID, this.TestEnvironment)
		VueApp.use(TranslationPlugin, 'it')
		VueApp.use(UserManagement)
		VueApp.use(CartPlugin, {
			depositPercentage: Options !== null && typeof Options.depositPercentage !== 'undefined' ? Options.depositPercentage : 1,
			allowAdvancePayment: Options !== null && typeof Options.allowAdvancePayment !== 'undefined' ? Options.allowAdvancePayment : false
		})
		VueApp.use(FormPlugin)

		VueApp.mount('#improoving-widget')
	}

	initializeListeners = () => {
		let _this = this;
		let openers = [].slice.call(document.querySelectorAll('.openImproovingWidget, .open-improoving-widget'));
		let closers = [].slice.call(document.querySelectorAll('.closeImproovingWidget, .close-improoving-widget'));

		openers.forEach(opener => {
			opener.addEventListener('click', event => {
				let section= event.target.dataset.section || null,
					categoryId= event.target.dataset.category || null,
					filterName= event.target.dataset.filtername || null,
					productType= event.target.dataset.type || null,
					productId = event.target.dataset.product || null;

				if(productId !== null && (productType !== null || section !== null))
					_this.openProduct(productType || section, productId);
				else if(section !== null && ["event", "giftCard"].includes(section))
					_this.openSection(section, null, filterName);
				else if(section !== null && categoryId !== null)
					_this.openSection(section, categoryId, filterName);
				else
					_this.open();
			});
		})
		closers.forEach(closer => {
			closer.addEventListener('click', () => {
				_this.close();
			});
		})

		// WARNING: let installations override if multiple widgets become supported
		document.addEventListener('openImproovingWidget', () => this.open());
		document.addEventListener('closeImproovingWidget', () => this.close());
		document.addEventListener('openImproovingSection', (e) => this.openSection(e.detail.section, e.detail.categoryId || null, e.detail.filterName || null));
		document.addEventListener('openImproovingProduct', (e) => this.openProduct(e.detail.productType, e.detail.productId));

	}
}

window.ImproovingWidget = ImproovingWidget

window.onload = () => {
	if(process.env.NODE_ENV === 'development'){
		console.warn("Modalità sviluppo abilitata");
		let school_id = 34,
			options = {
				// depositPercentage: 0.4,
				allowedSections: ["lesson"],
				packetText: {
					it: "",
					en: ""
				}
			};

		new ImproovingWidget("eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiNTRlOGY3MWIxOGY4YWQ4MTRiOTI1YzVjYjJiZTE4NmY3YTQ2NTZiZWM5YmJkYzkyNzRlMjU2ZTU4ZjE1NjIxYWFhNTQ5MzBhMTZmMDIxZjIiLCJpYXQiOjE2Njk3MjEyMDUsIm5iZiI6MTY2OTcyMTIwNSwiZXhwIjoxNzAxMjU3MjA1LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.fEEalijkAzrRX7WEvVnNrhK2LZG2leRXIKLu9BqCHrByL8IP-d-0tA8ZT7CjuioyMN3laWb8wCDDhgBU194DhXaA7OWVfgoZLCdjYkH0Medca5QL7nDSEy923wOoJ0eXtWky2hXBuju2ig0F9Aykc4A5c8lYYGc0UIw3YEzasQkDA7BvTso2Cq5mw-81ZFKE0QDSONYUzVG0scFdQDT8sTmwySksc_ovwtSapYmKLeqC4t-VdWkGajwdjE8jrMeU_deReWQ77uQN1WW958dR51XSZfiYaz_JVviv2Dc_GC9bF9sFsV55VGi8XsbFtGqpzN8o6DBeDNeynjZbCDrz5pzQJ3_LnfcRFaeYfZPNsaPz64zXJgOzdFw3u5Shf2yo_YGTaKUS7VH41E3FxwKmgQZeTNYlWUmNDKsOPOk2cM6LwHDJufHE-Yepo30JcCSA9JLKQYVq9ruMOteJ1JYT4ILNAtQxFytMKXZAgS_CnMXZyT2h4V1j3CwwvnFjPO0OkicaR7_HOybKzwMWvjb9JJ2goAfK468coCQN9Ldywvf7iuJv9UoTReuKrVzvVLIQyWI5aLKb32aRZBASQEveJPj9o2GSzdNOcMhOoAfYWbUt6xgzQTDzBJUT92fZghkYZyDek1UVTfUa_reFt26MjQFhpbtIfVEWtlhx35txKIU", school_id, options, true);

		// document.dispatchEvent(new CustomEvent('openImproovingWidget'));
		// document.dispatchEvent(new CustomEvent('openImproovingSection', {detail:{section: "packet", categoryId: 1}}));
		// document.dispatchEvent(new CustomEvent('openImproovingSection', {detail:{section: "event", filterName: "13"}}));
		/// document.dispatchEvent(new CustomEvent('openImproovingSection', {detail:{section: "packet", categoryId: 1, filterName: "finale"}}));
		// document.dispatchEvent(new CustomEvent('openImproovingSection', {detail:{section: "lesson", categoryId: 1}}));
		// document.dispatchEvent(new CustomEvent('openImproovingProduct', {detail:{productType: "packet", productId: 1619}}));
		document.dispatchEvent(new CustomEvent('openImproovingProduct', {detail:{productType: "giftCard", productId: 3}}));
		// Mettere un filtro sui corsi disponibili direttamente nell'evento e lasciare selezionabili le date

		// TODO: togliere l'apertura delle prime date
		// TODO: filtro su nome prodotto (data-filtername)
		// TODO: se entro da calendario sezione non salva la disciplina
	}
};