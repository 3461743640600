<style lang="scss">
#improoving-widget{
	.date-card {
		border-radius: 5px;
		padding: 20px 10px;
		box-shadow: 1px 1px 5px 2px darkgrey;
		margin-bottom: 15px;
	}
	.date-box{
		border-radius: 5px;
		border: 1px solid blue;
		box-shadow: 1px 1px 1px 1px blue;
		padding: 5px 0;
		font-weight: bold;
	}
}
</style>

<template>
	<div class="flow-lessons">
		<span @click="goBack"><Icon icon="arrow-left-circle mr-1 improoving-text"></Icon> {{ $trans('messages.back') }}</span>
		<div v-if="startTimesAreSelected && showTeacherSelection">
			<p>Indica la tua preferenza di maestro</p>
			<Teachers @selectTeacher="selectTeacher"></Teachers>

			<div class="d-grid px-5">
				<button	:class="`my-3 btn ${selectedTeacher === null ? 'btn-dark' : 'btn-outline-dark'}`" type="button" @click="selectedTeacher = null">
					Non ho preferenze sul maestro
				</button>
				<button	class="btn btn-primary my-3" type="button" @click="addLessonToCart">
					Aggiungi al carrello
				</button>
			</div>
		</div>
		<div v-else-if="viewStartingTimes" class="d-grid gap-2">
			<Loading v-if="loading"></Loading>
			<template v-else>
				<h5 class="font-inter font-inter-400 text-left">Seleziona la durata e l’orario delle Lezioni Private per i giorni selezionati</h5>
				<div v-for="dateClicked in clickedDates"
					:key="['date_clicked', dateClicked].join('.')"
					class="date-card">
					<div class="row mb-2">
						<div class="col-2">
							<div class="date-box text-center">
								{{ $momentItem(dateClicked).format('DD') }}
								<br/>{{ $momentItem(dateClicked).format('MMM') }}
							</div>
						</div>
						<div class="col d-flex flex-column align-items-center justify-content-around">
							<strong>{{ $momentItem(dateClicked).format('dddd') }}</strong>
							<strong>{{ $momentItem(dateClicked).format('DD MMMM YYYY') }}</strong>
						</div>
					</div>
					<div v-if="downloadedDays[dateClicked].validStartTimes.length > 0"
						class="form-group">
						<label>{{ $trans(['messages', 'duration'].join('.')) }}</label>
						<select class="form-control" @change="selectDuration(dateClicked, $event)">
							<option disabled :value="null">{{ $trans(['messages', 'duration'].join('.')) }}</option>
							<option v-for="possible_duration in downloadedDays[dateClicked].durations.available"
								:key="['possible_duration', dateClicked, possible_duration.minutes].join('.')"
								:value="possible_duration.minutes">{{ possible_duration.text }}</option>
						</select>
					</div>

					<template v-if="downloadedDays[dateClicked].validStartTimes.length > 0">
						<p class="text-center my-1"><strong>Seleziona l'orario della lezione</strong></p>
						<div class="d-grid px-5">
							<template v-for="start_time in downloadedDays[dateClicked].validStartTimes"
								:key="['start_time', dateClicked, start_time, downloadedDays[dateClicked].durations.selected].join('.')">
								<button v-if="typeof downloadedStartTimes[dateClicked][this.$schoolID][downloadedDays[dateClicked].durations.selected][start_time] !== 'undefined'"
									:class="`btn ${startTimes[dateClicked] === start_time ? 'btn-success' : 'btn-outline-improoving'} d-flex`"
									type="button"
									:disabled="!allowLessonWithoutAvailability && downloadedStartTimes[dateClicked][this.$schoolID][downloadedDays[dateClicked].durations.selected][start_time].available_association_ids.length === 0"
									@click="startTimes[dateClicked] = start_time">
									<div class="w-50 text-center">
										{{ $momentItemFormattedInterval([dateClicked, start_time].join(' '), $momentItem([dateClicked, start_time].join(' ')).add(parseInt(downloadedDays[dateClicked].durations.selected), 'minutes'), 'kk:mm') }}
									</div>
									<div class="w-50 text-center">
										{{ typeof downloadedStartTimes[dateClicked][this.$schoolID][downloadedDays[dateClicked].durations.selected][start_time] === 'undefined' || (!allowLessonWithoutAvailability && downloadedStartTimes[dateClicked][this.$schoolID][downloadedDays[dateClicked].durations.selected][start_time].available_association_ids.length === 0)  ? $trans('messages.notAvailable') : '('+$formattedPrice(downloadedStartTimes[dateClicked][this.$schoolID][downloadedDays[dateClicked].durations.selected][start_time].selling_price)+')' }} <!-- Should we show teacher number? -->
									</div>

								</button>
							</template>
						</div>

					</template>
					<h4 v-else>Non ci sono lezioni disponibili</h4>
				</div>

				<div class="d-grid px-5">
					<button class="btn btn-improoving my-2" type="button" @click="showTeacherSelection = true" :disabled="!startTimesAreSelected">
						{{ $trans('marketing.nextStep') }}
					</button>
				</div>

			</template>
		</div>
		<div class="w-100 text-center" v-else>
			<h5 class="font-inter font-inter-400 text-left">Seleziona i giorni di lezione ed il numero dei partecipanti</h5>
			<Calendar ref="calendar_lessons"
				:min-date="fromDate.toDate()"
				:max-date="toDate !== null ? toDate.toDate() : toDate"
				:attributes="calendarAttributes"
				@dayclick="dayClicked"
				@update:to-page="changedMonth"/>

			<div>
				<label>{{ $transChoice(['messages', 'day'].join('.'), 2) }}</label>
				<input class="form-control" :value="clickedDates.length" disabled />
			</div>

			<div>
				<label>{{ $trans(['messages', 'pupilsNumber'].join('.')) }}</label>
				<select v-model="pupilsNumber" class="form-control">
					<option disabled :value="null">{{ $trans(['messages', 'pupilsNumber'].join('.')) }}</option>
					<option v-for="number in maxPupilsNum"
						:key="`select_pupils.${number}`"
						:value="number">{{number}} {{ $transChoice(['messages', 'participant'].join('.'), number) }}</option>
				</select>
			</div>

			<div class="d-grid px-5">
				<button class="btn btn-improoving my-2" type="button" @click="viewStartingTimes = true" :disabled="loading || clickedDates.length === 0">
					{{ loading ? 'Loading...' : $trans('marketing.nextStep') }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import CheckoutMixin from "../mixins/checkout_steps"
import Icon from "./_partials/Icon";
import Loading from "./Loading";
import Teachers from "./Teachers";
import { Calendar } from 'v-calendar'
import {computed} from "vue";

export default {
	name: 'FlowLessons',
	mixins: [CheckoutMixin],
	inject: ['availableDurations', 'maxPupilsNum', 'category_id', 'pupils_num', 'age_group', 'level_group', 'offsetFromDate', 'offsetToDate', 'allowTeacherSelection', 'allowLessonWithoutAvailability'],
	components: {Calendar, Icon, Loading, Teachers},
	props: {
		minimumMinutesGreen: {
			type: Number,
			default: 1000
		},
		minimumMinutesYellow: {
			type: Number,
			default: 0
		}
	},
	data(){
		return {
			clickedDates: [],
			startDate: this.$momentItem().toDate(),
			startTimes: {},
			downloadedDays: {},
			downloadedStartTimes: {},
			selectedTeacher: null,
			loading: false,
			viewStartingTimes: false,
			pupilsNumber: this.pupils_num,
			showTeacherSelection: false,
			calendarPeriod: {
				month: this.$momentItem().month(),
				year: this.$momentItem().year()
			},
			downloadTimeout: false
		}
	},
	watch: {
		startTime(){
			this.selectedTeacher = null;
		},
		viewStartingTimes(newValue){
			if(newValue)
				this.downloadStartTimes();
		},
		durations(){
			let _this = this;
			Object.values(_this.downloadedDays).forEach(day_object => {
				_this.downloadedDays[day_object.date].validStartTimes = _this.mapValidStartTimes(day_object);
			});
		},
		clickedDates(newValue){
			let _this = this;
			_this.startTimes = {};
			newValue.forEach(date => {
				_this.startTimes[date] = null
			})
		},
		pupilsNumber(newValue){
			this.$emit('changePupilsNumber', newValue);
		},
		showTeacherSelection(newValue, oldValue){
			if(!oldValue && newValue && (!this.allowTeacherSelection || this.selectableAssociationIds.length === 0))
				this.addLessonToCart();
		},
		calendarPeriod(){
			clearTimeout(this.downloadTimeout);
			this.downloadTimeout = setTimeout(this.downloadDays, 250);
		}
	},
	provide(){
		return {
			selectableAssociationIds: computed(() => this.selectableAssociationIds),
			selectedTeacher: computed(() => this.selectedTeacher),
		}
	},
	computed: {
		calendarAttributes(){
			let _this = this;
			return Object.values(this.downloadedDays).reduce((acc, day_object) => {
				if(day_object.total_minutes > 0)
					acc.push({
						highlight: {
							color: day_object.total_minutes > _this.minimumMinutesGreen ? 'green' : (day_object.total_minutes > _this.minimumMinutesYellow ? 'yellow' : 'red'),
							fillMode: _this.clickedDates.includes(this.$momentItem(day_object.date).format('YYYY-MM-DD')) ? 'solid' : 'outline',
						},
						dates: this.$momentItem(day_object.date).toDate(),
					})
				return acc
			}, []);
		},
		fromDate(){
			return this.$momentItem().add(this.offsetFromDate, 'days');
		},
		toDate(){
			return this.offsetToDate !== null ? this.$momentItem().add(this.offsetToDate, 'days') : null;
		},
		startTimesAreSelected(){
			return this.clickedDates.length && this.clickedDates.length === Object.values(this.startTimes).filter(el => el !== null).length;
		},
		selectableAssociationIds(){
			let _this = this;
			return this.startTimesAreSelected ? this.clickedDates.reduce((acc, date) => {
				acc = acc.filter(el => _this.downloadedStartTimes[date][_this.$schoolID][_this.downloadedDays[date].durations.selected][_this.startTimes[date]].available_association_ids.includes(el));
				return acc;
			}, _this.downloadedStartTimes[_this.clickedDates[0]][_this.$schoolID][_this.downloadedDays[_this.clickedDates[0]].durations.selected][_this.startTimes[_this.clickedDates[0]]].available_association_ids) : [];
		},
		downloadableDays(){
			let start_of_month = this.$momentItem([this.calendarPeriod.year, this.calendarPeriod.month, '01'].join('-')).startOf('month'),
				end_of_month = this.$momentItem([this.calendarPeriod.year, this.calendarPeriod.month, '01'].join('-')).endOf('month');
			return {
				start_date: start_of_month.isBefore(this.fromDate) ? this.fromDate : start_of_month,
				end_date: end_of_month.isBefore(this.fromDate) ? this.fromDate : end_of_month
			}
		}
	},
	methods: {
		downloadDays(){
			let _this = this;

			if(typeof _this.downloadedDays[_this.downloadableDays.start_date.format('YYYY-MM-DD')] === 'undefined' && typeof _this.downloadedDays[_this.downloadableDays.end_date.format('YYYY-MM-DD')] === 'undefined'){
				_this.loading = true;

				// this.$requestGET(['school', this.$schoolID, 'statistics', 'availabilities'].join('/')).then(r => console.log(r.data))
				this.$requestGET(['searchresults'].join('/'), {
					school: this.$schoolID,
					category_id: this.category_id,
					start_date: this.downloadableDays.start_date.format('YYYY-MM-DD'),
					end_date: this.downloadableDays.end_date.format('YYYY-MM-DD'),
					include: {
						lessons: 'lessons'
					},
					pupils: this.pupils_num
				}).then(r => {
					console.log('received this data', r.data);
					r.data.lessons.forEach(lesson_item => {
						lesson_item.calendar_summary.forEach(day_object => {
							day_object.availableStartTimesMapped = day_object.available_start_times.split(',').sort((a, b) => { return a > b ? 1 : -1}) || [];
							day_object.closingTime = _this.$momentItem(day_object.date).hours(day_object.closing_time.split(':')[0]).minutes(day_object.closing_time.split(':')[1]) || null;
							day_object.durations = {
								selected: _this.availableDurations[0].minutes,
								available: _this.availableDurations
							}
							day_object.validStartTimes = _this.mapValidStartTimes(day_object);
							_this.downloadedDays[day_object.date] = day_object;
						});
					});

					_this.loading = false;
				})
			}
		},
		downloadStartTimes(){
			let _this = this;
			_this.loading = true;

			let promises = _this.clickedDates.map(date => {
				return _this.$requestGET(['available-start-times', this.category_id, date, _this.$schoolID].join('/'),{
					pupils_number: _this.pupils_num
				})
			});

			Promise.all(promises).then(response => {
				response.forEach((r, j) => {
					console.log('received this data', r.data);
					_this.downloadedStartTimes[this.$momentItem(this.clickedDates[j]).format('YYYY-MM-DD')] = r.data;
				});
			}).then(() => {
				_this.loading = false;
			});
		},
		dayClicked(day){
			let date = this.$momentItem(day.date).format('YYYY-MM-DD');
			if(Object.keys(this.downloadedDays).includes(date)){
				if(this.clickedDates.includes(date))
					this.clickedDates.splice(this.clickedDates.indexOf(date), 1);
				else
					this.clickedDates.push(date);
			}
		},
		selectTeacher($event){
			this.selectedTeacher = $event;
		},
		addLessonToCart(){
			let _this = this;
			this.clickedDates.forEach(date => {
				_this.downloadedStartTimes[date][_this.$schoolID][_this.downloadedDays[date].durations.selected][_this.startTimes[date]].slot_ids.forEach(slot_id => {
					_this.$addToCart({
						id: slot_id,
						category: {
							id: _this.category_id
						},
						available_teachers: _this.downloadedStartTimes[date][_this.$schoolID][_this.downloadedDays[date].durations.selected][_this.startTimes[date]].available_association_ids,
						pupils_num: _this.pupils_num,
						req_asso_id:  _this.selectedTeacher === null ? null : _this.selectedTeacher.id,
						price: _this.downloadedStartTimes[date][_this.$schoolID][_this.downloadedDays[date].durations.selected][_this.startTimes[date]].selling_price / _this.downloadedStartTimes[date][_this.$schoolID][_this.downloadedDays[date].durations.selected][_this.startTimes[date]].slot_ids.length
					}).then(() => {
						_this.$goToCart.value = true;
						_this.$goToPayerDetails.value = true;
					});
				});
			});
		},
		selectDuration(date, $event){
			console.log('duration was selected', $event.target.value);
			this.downloadedDays[date].durations.selected = $event.target.value;
			this.downloadedDays[date].validStartTimes = this.mapValidStartTimes(this.downloadedDays[date]);
		},
		mapValidStartTimes(day_object){
			return day_object.availableStartTimesMapped.filter(el => {
				let time = this.$momentItem(day_object.date).hours(el.split(':')[0]).minutes(el.split(':')[1])
				return time.add(day_object.durations.selected, 'minutes').isSameOrBefore(day_object.closingTime);
			});
		},
		selectPupilsNum($event){
			this.$emit('selectPupilsNum', $event);
		},
		goBack(){
			if(this.showTeacherSelection)
				this.showTeacherSelection = false;
			else if(this.viewStartingTimes)
				this.viewStartingTimes = false;
			else
				this.returnToWidget();
		},
		changedMonth($event){
			console.log('Date on calendar changed', $event);
			this.calendarPeriod = {
				month: $event.month,
				year: $event.year
			}
		}
	}
}
</script>