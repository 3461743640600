import { ref } from 'vue'

export default {
	install: (app) => {
		const user_key = 'improoving-logged_user'

		app.config.globalProperties.$goToCart = ref(false)
		app.config.globalProperties.$goToPayerDetails = ref(false)
		app.config.globalProperties.$goToPayment = ref(false)

		app.config.globalProperties.$loggedUser = ref(null)
		app.config.globalProperties.$loggedUserInfoIsUpdated = ref(false)
		app.config.globalProperties.$checkoutAsNewUser = ref(false)
		app.config.globalProperties.$checkoutAsNewUserInfo = ref(null)
		app.config.globalProperties.$CSRFToken = ref(null)

		app.config.globalProperties.$lastLoginMessage = ref(null)

		function loadUser(){
			app.config.globalProperties.$getFromStorage(user_key).then(r => {
				if(r !== null){
					console.log('Improoving User found', r)
					app.config.globalProperties.$loggedUser.value = JSON.parse(r)
					document.dispatchEvent(new CustomEvent('improovingUserLoaded', app.config.globalProperties.$loggedUser.value));
				}
			});
		}

		loadUser()

		app.config.globalProperties.$login = (email, password) => {
			app.config.globalProperties.$lastLoginMessage.value = null;
			return app.config.globalProperties.$requestPOST('login', {email: email, password: password})
			.then(r => {
				if(r.data.login){
					app.config.globalProperties.$checkoutAsNewUser.value = false;
					app.config.globalProperties.$requestSchoolDatabase('customers', {where: [{key: 'email', value: encodeURIComponent(email)}]})
					.then(r => {
						console.log('Response from login', r.data);
						if(Object.values(r.data).length === 1){
							let user = Object.assign({}, Object.values(r.data)[0].person.account),
								person = Object.assign({}, Object.values(r.data)[0].person)

							delete person.account;

							user.person = Object.assign({}, person)

							app.config.globalProperties.$saveInStorage(user_key, JSON.stringify(user)).then(() => {
								loadUser()
							})
						}
					})
				}
				else if(typeof r.data.error != 'undefined' && r.data.error !== null)
					app.config.globalProperties.$lastLoginMessage.value = r.data.error;
			})
		}

		app.config.globalProperties.$createUser = (email, password, first_name, last_name, phone) => {
			app.config.globalProperties.$lastLoginMessage.value = null;
			return app.config.globalProperties.$requestPOST(['school', app.config.globalProperties.$schoolID, 'people', 'store-many'].join('/'), {data_collection: true, person: {0: {0: {email: email, password: password, password_confirmation: password, first_name: first_name, last_name: last_name, phone: phone}}}})
			.then(r => {
				if(r.data.status === 201){
					app.config.globalProperties.$login(email, password)
				}
				else if(typeof r.data['person[0][0][email]'] != 'undefined' && r.data['person[0][0][email]'] !== null)
					app.config.globalProperties.$lastLoginMessage.value = r.data['person[0][0][email]'];
			})
		}

		app.config.globalProperties.$storeRelative = (first_name, last_name, birthday = null, languages = [], assessment = null) => {
			app.config.globalProperties.$pruneRequestCache(['relatives', app.config.globalProperties.$loggedUser.value.person_id, app.config.globalProperties.$schoolID].join('/'))
			return app.config.globalProperties.$requestPOST(['relatives', app.config.globalProperties.$loggedUser.value.person_id, 'store', app.config.globalProperties.$schoolID].join('/'),
				{data_collection: true,
					[app.config.globalProperties.$transChoice('form_db.first_name', 1).toLowerCase()]: first_name,
					[app.config.globalProperties.$transChoice('form_db.last_name', 1).toLowerCase()]: last_name,
					birthday: birthday,
					languages: languages,
					assessment: assessment,
					schools: {[app.config.globalProperties.$schoolID]: {consents: {data_collection:{consent_type: "data_collection", record_updated: true, has_given_consent: true}}}}}
			)
		}

		app.config.globalProperties.$logout = () => {
			return app.config.globalProperties.$removeFromStorage(user_key).then(() => {
				app.config.globalProperties.$loggedUser.value = null
				app.config.globalProperties.$loggedUserInfoIsUpdated.value = false
				document.dispatchEvent(new CustomEvent('improovingUserLogout'));
			})
		}

		app.config.globalProperties.$getUserRelatives = () => {
			return new Promise((resolve) => {
				if(app.config.globalProperties.$loggedUser.value === null)
					resolve([])

				app.config.globalProperties.$requestGET(['relatives', app.config.globalProperties.$loggedUser.value.person_id, app.config.globalProperties.$schoolID].join('/')).then(r => {
					resolve(Object.values(r.data))
				})
			})
		}

		app.config.globalProperties.$personAttribute = (person, attribute) => {
			if(person[attribute])
				return person[attribute];
			else if(typeof person.person !== 'undefined')
				return app.config.globalProperties.$personAttribute(person.person, attribute);
			else if(!personHasAccount(person))
				throw Error('Person does not have account')
			else
				return person.account[attribute];
		}

		app.config.globalProperties.$personIdentifier = (person, identifier = app.config.globalProperties.$trans('messages.accountNotAvailable')) => {
			try{
				if(person.identifier)
					return person.identifier;
				else if(typeof person.person !== 'undefined')
					identifier = app.config.globalProperties.$personIdentifier(person.person, identifier);
				else if(!personHasAccount(person))
					identifier = app.config.globalProperties.$transChoice('messages.person', 1)+' '+ (typeof person.id !== 'undefined' ? person.id : '***');
				else
					identifier = person.account.last_name.toUpperCase().trim()
						+ ' '
						+ person.account.first_name.toLowerCase()
						.replace(/^[\u00C0-\u1FFF\u2C00-\uD7FF\w]|\s[\u00C0-\u1FFF\u2C00-\uD7FF\w]/g, function(letter) {
							return letter.toUpperCase();
						});
				person.identifier = identifier;
			}
			catch(err){
				console.warn(err);
				console.warn(person);
			}
			return identifier;
		}

	}
}

function personHasAccount(person){
	return typeof person.account !== 'undefined'
		&& person.account !== null;
}