import axios from "axios";

export default {
	install: (app, schoolID, isTestEnvironment = false) => {

		let requests = {};

		app.config.globalProperties.$isTestEnvironment = isTestEnvironment;
		app.config.globalProperties.$baseUrl = isTestEnvironment ? 'https://test.improoving.me' : 'https://improoving.me';
		app.config.globalProperties.$apiBase = isTestEnvironment ? 'https://test.improoving.me/api' : 'https://improoving.me/api';
		app.config.globalProperties.$schoolID = schoolID;

		app.config.globalProperties.$requestSchoolDatabase = (url, payload = {}) => {
			return app.config.globalProperties.$requestGET(['school-database', app.config.globalProperties.$schoolID, url].join('/'), payload);
		}

		app.config.globalProperties.$pruneRequestCache = (request_name) => {
			return new Promise(resolve => {
				delete(requests[request_name])
				resolve('completed');
			});
		}

		app.config.globalProperties.$requestGET = (url, payload = null) => {
			if(payload !== null)
				url += '?' + stringifyPayload(payload)

			if(typeof requests[url] === 'undefined')
				requests[url] = axios.get([app.config.globalProperties.$apiBase, url].join('/'));

			return requests[url];
		}

		app.config.globalProperties.$requestPOST = (url, payload) => {
			return axios.post([app.config.globalProperties.$apiBase, url].join('/'), payload);
		}

		app.config.globalProperties.$requestPOSTFormData = (url, formData) => {
			return axios({
				method: 'post',
				url: [app.config.globalProperties.$apiBase, url].join('/'),
				data: formData,
				headers: { "Content-Type": "multipart/form-data" }
			});
		}

		app.config.globalProperties.$addMissingFields = (variation, origin) => {
			Object.keys(origin).forEach(key => {
				if(typeof variation[key] === 'undefined')
					variation[key] = JSON.parse(JSON.stringify(origin[key]));
			});

			return variation;
		}
	}
}

function stringifyPayload(payload){
	return Object.keys(payload).reduce((acc, key) => {
		if(payload[key] instanceof Array){
			let result2 = payload[key].reduce((acc2, item, index) => {
				let result = Object.keys(item).reduce((acc3, object) => {
					acc3.push([`${key}[${index}][${object}]`, payload[key][index][object]].join('='))
					return acc3
				}, []).join('&')

				acc2.push(result)
				return acc2
			}, []).join('&')

			acc.push(result2)
		}
		else if(payload[key] instanceof Object){
			let object_keys = Object.keys(payload[key]);
			let result2 = Object.values(payload[key]).reduce((acc2, item, index) => {
				let current_key = object_keys[index];
				acc2.push([`${key}[${current_key}]`, payload[key][current_key]].join('='))
				return acc2
			}, []).join('&')

			acc.push(result2)
		}
		else
			acc.push([key, payload[key]].join('='))

		return acc
	}, []).join('&')
}