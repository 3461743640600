<template>
	<header :class="currentCheckoutStep+'-step'">
		<div class="d-flex justify-content-end">
			<span @click="closeWidget">{{ $trans('messages.close') }} <Icon icon="x-circle ml-1"></Icon></span>
		</div>
		<div class="row my-1">
			<div class="col">
				<div v-if="$loggedUser.value !== null">
					<strong>{{ [$personAttribute($loggedUser.value, 'last_name').toUpperCase(), $personAttribute($loggedUser.value, 'first_name').toLowerCase()].join(' ') }}</strong> <a href="#" @click="$logout">{{ $trans('messages.logout') }}</a>
					<br>
					{{ $personAttribute($loggedUser.value, 'email') }}

				</div>
				<div v-else-if="$checkoutAsNewUserInfo.value !== null">
					<em>{{ $trans('checkout.checkoutAsUnknown_extra') }}</em>
				</div>
				<!-- <img :alt="schoolData.name+' logo'" :src="logoUrl" @click="closeWidget" class="logo img-fluid"> -->
			</div>
		</div>
	</header>

	<div id="main-content" :class="currentCheckoutStep+'-step'">
		<div v-if="currentCheckoutStep === 'payment-successful'">
			<h2>Il tuo pagamento è andato a buon fine</h2>
			<p>Non abbiamo ancora effettuato il prelievo sul metodo di pagamento fornito; stiamo rivedendo la tua prenotazione e verrai contattato per la conferma.</p>
		</div>
		<Cart v-else-if="currentCheckoutStep === 'payment'"></Cart>
		<PayerDetails v-else-if="currentCheckoutStep === 'payer-details'"></PayerDetails>
		<FlowGiftCards v-else-if="showFlowGiftCards" @returnToWidget="section = null"></FlowGiftCards>
		<FlowLessons v-else-if="showFlowLesson"
			@returnToWidget="section = null"
			@changePupilsNumber="pupils_num = $event"></FlowLessons>
		<FlowPackets v-else-if="showFlowPacket" @returnToWidget="section = null"></FlowPackets>
		<FlowPackets v-else-if="showFlowEvent" packetType="event" @returnToWidget="section = showSectionEventsOnly ? 'flowEvent' : null"></FlowPackets>
		<div class="d-grid gap-1 gap-md-3" v-else>
			<a v-if="section !== null || product !== null" href="#" @click="product = null; section = null">{{ $trans(['messages', 'unselectAll'].join('.')) }}</a>

			<template v-if="showInitialSelection">
				<template v-for="category in availableCategories"
					:key="`category_box.${category.id}`">
					<h3 class="font-inter font-inter-700 mb-0">{{ $trans('translations.'+category.specialization) }}</h3>
					<Box v-if="showSectionTeachers"
						:showTitle="false"
						:category="category"
						@selectCategory="selectSection('flowLesson', category)"></Box>

					<Box v-if="showSectionPackets"
						:showTitle="false"
						:jumpToSection="{section: 'packet'}"
						@selectSection="selectSection('flowPacket', category)"></Box>

					<!--
					<Box v-if="showSectionEvents"
						:showTitle="false"
						:jumpToSection="{section: 'event'}"
						@selectSection="selectSection('flowEvent')"></Box>
						-->
				</template>

				<template v-if="showSectionEvents || showFlowGiftCards">
					<h3 class="font-inter font-inter-700 mb-0">{{ $trans('messages.other') }}</h3>
					<Box v-if="showSectionEvents"
						:jumpToSection="{section: 'event'}"
						@selectSection="selectSection('flowEvent')"></Box>

					<Box v-if="showSectionGiftCards"
						:jumpToSection="{section: 'giftCard'}"
						@selectSection="selectSection('flowGiftCard')"></Box>
				</template>

				<!--
				<template v-if="showSectionTeachers">
					<Box v-for="category in availableCategories"
						:key="`select_category_box.${category.id}`"
						:category="category"
						@selectCategory="selectSection('flowLesson', category)"></Box>
				</template>

				<Box v-if="showSectionPackets"
					:jumpToSection="{section: 'packet'}"
					@selectSection="selectSection('flowPacket')"></Box>

					-->


				<!--
				<div class="form-group">
					<label>{{ $transChoice('messages.levelGroup', 1) }}</label>
					<select v-model="level_group" class="form-control">
						<option disabled :value="null">{{ $transChoice(['messages', 'levelGroup'].join('.'), 1) }}</option>
						<option v-for="levelGroup in allowedLevelGroups"
								:key="`select_level_group.${levelGroup}`"
								:value="levelGroup">{{ $trans(['translations', levelGroup].join('.')) }}</option>
					</select>
				</div>

				<div class="form-group">
					<label>{{ $transChoice(['messages', 'age'].join('.'), 1) }}</label>
					<select v-model="age_group" class="form-control">
						<option disabled :value="null">{{ $transChoice(['messages', 'age'].join('.'), 1) }}</option>
						<option v-for="(age, group_name) in allowedAgeGroups"
								:key="`age_groups.${group_name}`"
								:value="age">{{ $trans(group_name).toUpperCase() }}, ({{[age.min_age,age.max_age].join('-')}} {{$transChoice('messages.year', 2).toLowerCase()}})</option>
					</select>
				</div>
				-->
			</template>

		</div>
	</div>

	<footer :class="currentCheckoutStep+'-step'">
		<!--
		<div v-if="!['flowPacket', 'flowEvent'].includes(section)" class="d-grid my-2">
			<button v-if="currentCheckoutStep === 'initial'" class="btn btn-improoving" @click="goToPayerDetails" :disabled="this.$cartTotal.value === 0">
				{{ this.$cartTotal.value === 0 ? $trans('messages.cartIsEmpty') : [$trans('checkout.proceedToPayment'), $cartTotalFormatted.value].join(' ') }} <Icon icon="cart2"></Icon>
			</button>
		</div>
		-->

		<div class="row">
			<div class="col-md-2"></div>
			<div class="col-md-8 text-center">
				<!--
				<div class="d-grid my-2">
					<button v-if="currentCheckoutStep === 'initial'" class="btn btn-warning" @click="goToPayerDetails" :disabled="this.$cartTotal.value === 0">
						{{ this.$cartTotal.value === 0 ? $trans('messages.cartIsEmpty') : [$trans('checkout.proceedToPayment'), $cartTotalFormatted.value].join(' ') }}
					</button>
				</div>
				-->
				<span class="improoving-text">Designed with <Icon icon="heart-fill font-sm heartbeat-animation"></Icon> by</span><br/>
				<a href="https://improoving.me/our-products" target="_blank" class="hide-decoration">&copy; Improoving.me {{ new Date().getFullYear() }}</a>
			</div>
			<div class="col-md-2">
				<div class="d-flex justify-content-end h-100">
					<img	v-for="language in availableLanguages"
						:key="`language.${language}`"
						class="improoving-flag"
						@click="$changeLanguage(language)"
						:src="`https://improoving.me/flags/4x3/${language === 'en' ? 'gb' : language}.svg`" />
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
import { computed } from "vue"
import Box from "./components/_partials/Box";
import Cart from "./components/Cart";
import CheckoutMixin from "./mixins/checkout_steps"
import FlowGiftCards from "./components/FlowGiftCards";
import FlowLessons from "./components/FlowLessons";
import FlowPackets from "./components/FlowPackets";
import Icon from "./components/_partials/Icon";
import PayerDetails from "./components/PayerDetails";
// import StripeCheckout from "./components/StripeCheckout";

export default {
	name: 'SideWidget',
	props: {
		schoolId: {
			type: Number,
			required: true
		},
		allowedSections: {
			type: Array,
			default(){
				return ['packet', 'event', 'moment', 'lesson', 'teacher', 'giftCard']
			}
		},
		allowedLevelGroups: {
			type: Array,
			default(){
				return ['Novice', 'Beginner', 'Intermediate', 'Expert']
			}
		},
		allowedAgeGroups: {
			type: Object,
			default(){
				return {
					baby: {min_age: 0, max_age: 4},
					kid: {min_age: 5, max_age: 8},
					youth: {min_age: 9, max_age: 14},
					adult: {min_age: 15, max_age: 99}
				}
			}
		},
		availableLanguages: {
			type: Array,
			default(){
				return ['it', /*'en',*/ 'fr']
			}
		},
		availableLanguagesSpoken: {
			type: Object,
			default(){
				return {
					1: "English",
					2: "French",
					3: "German",
					4: "Italian",
					6: "Russian",
					7: "Spanish",
				}
			}
		},
		allowedDurations: {
			type: Array,
			default(){
				return [
					{minutes: 60, text: '1 Ora'},
					{minutes: 120, text: '2 Ore'},
					{minutes: 180, text: '3 Ore'},
					{minutes: 240, text: 'Mezza Giornata (4 Ore)'},
					{minutes: 480, text: 'Giornata Intera (8 Ore)'},
				]
			}
		},
		blockedCategories: {
			type: Array,
			default(){
				return []
			}
		},
		maxPupilsNumber: {
			type: Number,
			default: 8
		},
		offsetFromDate: {
			type: Number,
			default: 1
		},
		offsetToDate: {
			type: Number,
			default: null
		},
		allowTeacherSelection: {
			type: Boolean,
			default: true
		},
		allowLessonWithoutAvailability: {
			type: Boolean,
			default: true
		},
		showPriceOfIncludedServices: {
			type: Boolean,
			default: false
		},
		availableAssessments: {
			type: Object,
			default(){
				return {
					1: "Novice",
					2: "Beginner",
					3: "Intermediate",
					4: "Advanced",
				}
			}
		}
	},
	data() {
		return {
			schoolData: {},
			teachers: {},
			services: {},
			product: null,
			section: null,
			category_id: null,
			pupils_num: 1,
			age_group: null,
			level_group: null,
			filter_name: null
		}
	},
	provide(){
		return {
			age_group: computed(() => this.age_group),
			allowDateSelection: computed(() => true),
			allowTeacherSelection: this.allowTeacherSelection,
			allowLessonWithoutAvailability: this.allowLessonWithoutAvailability,
			showPriceOfIncludedServices: this.showPriceOfIncludedServices,
			availableAssessments: this.availableAssessments,
			availableCategories: this.availableCategories,
			availableDurations: computed(() => this.availableDurations),
			availableLanguagesSpoken: computed(() => this.availableLanguagesSpoken),
			blockedCategories: this.blockedCategories,
			category_id: computed(() => this.category_id),
			level_group: computed(() => this.level_group),
			filter_name: computed(() => this.filter_name),
			logoUrl: this.logoUrl,
			maxPupilsNum: this.maxPupilsNumber,
			offsetFromDate: this.offsetFromDate,
			offsetToDate: this.offsetToDate,
			pupils_num: computed(() => this.pupils_num),
			schoolData: computed(() => this.schoolData),
			schoolId: this.schoolId,
			section: computed(() => this.section),
			services: computed(() => this.services),
			showSectionEventsOnly: computed(() => this.showSectionEventsOnly),
			teachers: computed(() => this.teachers),
		}
	},
	components: {
		Box, Icon,
		FlowGiftCards, FlowLessons, FlowPackets, Cart, PayerDetails //, StripeCheckout
	},
	mixins: [CheckoutMixin],
	watch: {
		showFlowLesson(newValue){
			if(newValue && Object.values(this.teachers).length === 0)
				this.downloadTeachers();
		},
		section(newValue, oldValue){
			if(newValue === null && oldValue !== null)
				this.filter_name = null;
		}
	},
	computed: {
		logoUrl(){
			return this.$isTestEnvironment ? 'https://test.improoving.me/logo-image/'+this.schoolId+'/512' : 'https://improoving.me/logo-image/'+this.schoolId+'/512';
		},
		showInitialSelection(){
			return this.showSectionLessons || this.showSectionTeachers || this.showSectionPackets || this.showSectionMoments;
		},
		showSectionPackets(){
			return this.allowedSections.includes('packet');
		},
		showSectionEvents(){
			return this.allowedSections.includes('event');
		},
		showSectionMoments(){
			return this.allowedSections.includes('moment');
		},
		showSectionTeachers(){
			return this.allowedSections.includes('teacher');
		},
		showSectionLessons(){
			return this.allowedSections.includes('lesson');
		},
		showSectionGiftCards(){
			return this.allowedSections.includes('giftCard');
		},
		showSectionEventsOnly(){
			return this.showSectionEvents && !this.showInitialSelection;
		},
		showFlowGiftCards(){
			return this.section === 'flowGiftCard';
		},
		showFlowLesson(){
			return this.section === 'flowLesson';
		},
		showFlowPacket(){
			return this.section === 'flowPacket';
		},
		showFlowEvent(){
			return this.section === 'flowEvent';
		},
		availableCategories(){
			return typeof this.schoolData.categories !== 'undefined' ? Object.values(this.schoolData.categories).reduce((acc, category) => {
				if(!category.limited_to_school && (this.blockedCategories.length === 0 || !this.blockedCategories.includes(parseInt(category.id))))
					acc.push(category);

				return acc;
			}, []) : {};
		},
		availableDurations(){
			let _this = this;
			return typeof this.schoolData.options !== 'undefined'
			&& typeof this.schoolData.options.online_booking_minimum_length_minutes !== 'undefined'
			&& typeof this.schoolData.options.online_booking_minimum_incremental_minutes !== 'undefined' ?
				this.allowedDurations.reduce((acc, duration) => {
					if(duration.minutes >= _this.schoolData.options.online_booking_minimum_length_minutes && (duration.minutes - _this.schoolData.options.online_booking_minimum_length_minutes) % _this.schoolData.options.online_booking_minimum_incremental_minutes === 0);
						acc.push(duration)
					return acc;
				}, []) : this.allowedDurations;
		}
	},
	created() {
		document.addEventListener(['show', 'section'].join('-'), (e) => {
			if(this.allowedSections.includes(e.detail.section)){
				if(e.detail.category_id !== null && Object.values(this.availableCategories).length)
					this.selectSection(["flow",e.detail.section.charAt(0).toUpperCase(),e.detail.section.slice(1)].join(""), Object.values(this.availableCategories).find(el => el.id === parseInt(e.detail.category_id)), e.detail.filter_name);
				else if(e.detail.category_id !== null){
					// Debounce in case we haven't loaded the categories yet
					setTimeout(() => {
						document.dispatchEvent(new CustomEvent('show-section', e));
					}, 500);
				}
				else
					this.selectSection(["flow",e.detail.section.charAt(0).toUpperCase(),e.detail.section.slice(1)].join(""), null, e.detail.filter_name || null);
			}
			else
				alert(this.$trans('sectionNotFound'))
		});

		document.addEventListener(['show', 'product'].join('-'), (e) => {
			if(this.allowedSections.includes(e.detail.productType)){
				// TODO: instead of looking at the DB, download the public pages when the widget is opened
				if(["packet", "event"].includes(e.detail.productType)){
					this.$requestGET(['school-database', this.schoolId, e.detail.productType+'s'].join('/'))
					.then(r => {
						let product_found = Object.values(r.data).reduce((acc, el) => {
							if(acc !== null)
								return acc;
							else if(parseInt(el.id) === parseInt(e.detail.productId))
								acc = el;
							else if(typeof el.variations !== 'undefined' && el.variations.length){
								let variation_found = el.variations.find(variation => {
									return parseInt(variation.id) === parseInt(e.detail.productId);
								});
								if(typeof variation_found !== 'undefined')
									acc = this.$addMissingFields(variation_found, el);
							}

							return acc;
						}, null);

						if(product_found !== null){
							this.selectSection(["flow",e.detail.productType.charAt(0).toUpperCase(),e.detail.productType.slice(1)].join(""))
							this.$nextTick(() => {
								document.dispatchEvent(new CustomEvent('select-packet', {detail:{packet: JSON.stringify(product_found)}}));
							});
						}
						else
							alert(this.$trans('productNotFound'));
					})
				}
				else if(e.detail.productType === 'giftCard'){
					this.$requestGET(['school-database', this.schoolId, (e.detail.productType+'s').toLowerCase()].join('/'))
					.then(r => {

						console.log('database scaricato', r.data);
						let product_found = Object.values(r.data).reduce((acc, el) => {
							if(acc !== null)
								return acc;
							else if(parseInt(el.id) === parseInt(e.detail.productId))
								acc = el;

							return acc;
						}, null);

						if(product_found !== null){
							this.selectSection(["flow",e.detail.productType.charAt(0).toUpperCase(),e.detail.productType.slice(1)].join(""))
							this.$nextTick(() => {
								document.dispatchEvent(new CustomEvent('select-giftCard', {detail:{giftCard: JSON.stringify(product_found)}}));
							});
						}
						else
							alert(this.$trans('productNotFound'));
					})
				}
			}
			else
				alert(this.$trans('sectionNotFound'));
		});

		this.$requestGET(['school-database', this.schoolId, 'schooldata'].join('/'))
		.then(r => {
			this.schoolData = r.data
		});
		this.$requestGET(['school-database', this.schoolId, 'services'].join('/'))
		.then(r => {
			this.services = r.data
			document.dispatchEvent(new CustomEvent('loadedImproovingProducts-services'))
		})

		if(!this.showInitialSelection && this.showSectionEventsOnly)
			this.selectSection('flowEvent');
		else
			this.downloadTeachers();

	},
	methods: {
		closeWidget(){
			document.dispatchEvent(new Event('closeImproovingWidget'));
		},
		selectSection(section, category = null, filter_name = null){
			if(category !== null)
				this.category_id = category.id;

			if(filter_name !== null)
				this.filter_name = filter_name;

			this.section = section;
		},
		selectProduct($payload){
			this.product = $payload;
		},
		goToPayerDetails(){
			this.$goToCart.value = true;
			this.$goToPayerDetails.value = true;
		},
		downloadTeachers(){
			this.$requestGET(['school-database', this.schoolId, 'teachers'].join('/'))
			.then(r => {
				this.teachers = r.data
				document.dispatchEvent(new CustomEvent('loadedImproovingProducts-teacher'))
			});
		},
		backFunction(){}
	}

}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Quicksand:wght@300;400;500;600;700&family=Radio+Canada:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700');

#improoving-widget{
	@import 'bootstrap/scss/bootstrap';

	// Font Variables
	$font-Inter: 'Inter', sans-serif;
	$font-Quicksand: 'Quicksand', sans-serif;
	$font-RadioCanada: 'Radio Canada', sans-serif;

	@keyframes heartbeat-improoving{
		/* Optimized for a 4s infinite loop */
		0%{transform: scale( 1 );}
		10%{transform: scale( .75 );}
		15%{transform: scale( 1.2 );}
		20%{transform: scale( .75 );}
		25%{transform: scale( 1.2 );}
		30%{transform: scale( 1 );}
		100%{transform: scale( 1 );}
	}

	position: fixed;
	top: 0;
	bottom: 0;
	right: -450px;
	width: 450px;
	min-width: 300px;
	z-index: 10000;
	transition: right 1s ease-out;
	border: 1px solid lightgrey;
	border-left: 2px solid rgba(238, 139, 82, 1);
	padding: 5px 0;
	background-color: rgba(255, 252, 250, 1);
	box-shadow: 5px 5px 15px 5px rgba(238, 139, 82, 1); /* School color? */
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */

	font-size: 16px !important;
	font-family: $font-Quicksand;

	&.widget-box-shown{
		right: 0 !important;
		max-width: fit-content !important;
		display: flex;
		flex-direction: column;
	}

	/* Utilities */
	.font-inter{
		font-family: $font-Inter;
		&.font-inter-400{
			font-weight: 400;
			line-height: 1.5em;
			letter-spacing: 0;
		}
		&.font-inter-700{
			font-weight: 700;
			line-height: 1.875em;
			letter-spacing: 0;
		}
	}
	.font-quicksand{font-family: $font-Quicksand;}
	.font-radiocanada{font-family: $font-RadioCanada;}

	.hide-decoration{ text-decoration: none; color: inherit; }
	.heartbeat-animation{
		animation: heartbeat-improoving 4s infinite;
		display: inline-block;
	}

	hr.dashed{ border-top: 1px dashed gray; }
	hr.dotted{ border-top: 1px dotted gray; }

	> *{
		padding-left: 15px;
		padding-right: 15px;
	}

	> #main-content{
		flex-grow: 1;
		overflow: auto;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	> footer{
		font-size: 0.7em;
	}

	.form-control{
		height: 2.375em;
	}

	.improoving-flag{
		width: 25px;
		max-width: 25px;
		height: auto;
		cursor: pointer;
		margin: 5px;
	}
	.improoving-text{
		color: rgba(238, 139, 82, 1) !important;
	}
	.improoving-alert{
		color: rgba(227, 130, 126, 1) !important;
	}
	.text-danger{
		color: red !important;
	}
	.btn{
		font-weight: bold !important;
	}
	.btn-improoving{
		background-color: rgba(238, 139, 82, 1);
		color: white;
		&:hover{ background-color: rgba(238, 139, 82, 0.7) !important; }
		&:disabled{ background-color: rgba(137, 150, 159, 0.7) !important; }
	}
	.btn-outline-improoving{
		border-color: rgba(238, 139, 82, 1);
		color: black;
	}

	/* Generic Graphics */
	> .align-middle{ margin: auto 0; }
	.logo{
		max-height: 100px;
	}

	ul.scrollmenu {
		overflow: auto;
		white-space: nowrap;
		list-style: none;
		padding: 5px;
		margin: 10px 0 30px 0;

		li {
			display: inline-block;
			width: 40%;
			border: 1px dashed black;
			margin: 0 5px ;
			padding-top: 10px;
			border-radius: 5px;
			text-align: center;
			cursor: pointer;
		}
		li:hover {
			background-color: #777;
		}
		li > * { margin-bottom: 0; }
		li .days {
			text-transform: uppercase;
			font-size: 1.5em;
		}
	}

	nav.checkout-steps {
		margin-bottom: 3px;

		> * {
			font-size: small;
			padding: 3px 0;
			color: inherit !important;
			border-bottom: 3px solid orange;
			border-radius: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-left: 1px;
			margin-right: 1px;
			&.active {
				background-color: transparent;
				border-bottom: 3px solid blue;
				color: white !important;
			}
		}
	}

	.badge{
		background: rgba(51, 6, 178, 0.93);
		filter: drop-shadow(4px 4px 7px rgba(16, 191, 247, 0.44));
		color: white;
		border-radius: 3px;
		display: flex;
		align-items: center;

		font-family: $font-Inter;
		font-weight: 400;
		font-size: 1em;
		line-height: 1em;

		&.badge-sm{ font-weight: normal; }
		&.badge-blue{ background: rgba(45, 167, 154, 1) !important; }
		&.badge-pink{ background: rgba(227, 130, 126, 1) !important; }
		&.badge-violet{ background: rgba(151, 71, 255, 1) !important; }
	}

	.card-price {
		display: inline-block;

		width: auto;
		height: 24px;
		min-width: 75px;
		text-align: center;

		background-color: #6ab070;
		-webkit-border-radius: 3px 4px 4px 3px;
		-moz-border-radius: 3px 4px 4px 3px;
		border-radius: 3px 4px 4px 3px;

		border-left: 1px solid #6ab070;

		/* This makes room for the triangle */
		margin-left: 12px;

		position: relative;

		color: white;
		font-weight: 300;
		font-size: 16px;
		line-height: 16px;

		padding: 0 10px 0 10px;

		/* Makes the circle */
		&:before {
			content: "";
			position: absolute;
			display: block;
			left: -12px;
			width: 0;
			height: 0;
			border-top: 12px solid transparent;
			border-bottom: 12px solid transparent;
			border-right: 12px solid #6ab070;
		}
		/* Makes the triangle */
		&:after {
			content: "";
			background-color: white;
			border-radius: 50%;
			width: 4px;
			height: 4px;
			display: block;
			position: absolute;
			left: -3px;
			top: 11px;
		}
	}

	@media (min-width: 600px) {
		min-width: 450px;
	}
}
</style>