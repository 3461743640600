export default {
	/*
	Checkout Steps:
	- ask to login or create user
	- confirm data we have in database (GDPR)
	- review your cart
	- provide payment card
	- show success
	 */
	computed: {
		showCart(){
			return this.$cartCount.value > 0 && this.$goToCart.value;
		},
		showPayerDetails(){
			return this.showCart && this.$goToPayerDetails.value;
		},
		showCartReview(){
			return this.showCart && this.payerInfoIsDeclared;
		},
		showPaymentPage(){
			return this.showCartReview && this.$goToPayment.value;
		},
		showPaymentSuccessfulPage(){
			return this.$orderID.value !== null;
		},
		payerInfoIsDeclared(){
			return this.$loggedUserInfoIsUpdated.value;
		},
		currentCheckoutStep(){
			if(this.showPaymentSuccessfulPage)
				return 'payment-successful';
			else if(this.showPaymentPage)
				return 'payment';
			else if(this.showPayerDetails)
				return 'payer-details';
			else if(this.showCartReview)
				return 'cart-review';

			return 'initial';
		}
	},
	watch: {
		currentCheckoutStep(newValue){
			if(newValue === 'payment-successful')
				this.$emptyCart();
		}
	},
	methods:{
		returnToWidget(){
			this.$emit('returnToWidget')
		}
	}
}