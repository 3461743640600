<style>
#improoving-widget .month-navigation{
	color: white;
	background-color: darkorange;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 2px 5px;
}
#improoving-widget .month-navigation .navigate-to-month{
	cursor: pointer;
	min-width: 50px;
	text-align: center;
}
#improoving-widget .month-navigation .month-this{
	font-size: larger;
	font-weight: bold;
}
#improoving-widget .packets .list-group-item h1{
	font-size: 16px;
}
#improoving-widget .packets .list-group-item em{
	font-size: 12px;
}
</style>

<template>
	<div class="packets">
		<loading v-if="!loaded"></loading>
		<div v-else-if="availablePackets.length === 0" class="w-100 text-center">
			<h5>{{ $trans('no_'+packetType) }}</h5>
		</div>
		<template v-else-if="showPackets">
			<div v-if="hasAvailablePackets"
				class="month-navigation">
				<span class="navigate-to-month month-prev" @click="monthPrevious !== null ? selectedMonthId-- : 0">
					<template v-if="monthPrevious !== null"><Icon icon="chevron-left"></Icon> {{ $momentItem(monthPrevious.year_month).format('MMM') }}</template>
				</span>
				<span class="month-this">{{ $momentItem(monthCurrent.year_month).format('MMMM') }}</span>
				<span class="navigate-to-month month-next" @click="monthNext !== null ? selectedMonthId++ : Object.values(availablePacketsByMonth).length">
					<template v-if="monthNext !== null">{{ $momentItem(monthNext.year_month).format('MMM') }} <Icon icon="chevron-right"></Icon></template>
				</span>
			</div>
			<div v-if="false" class="d-flex justify-content-between">
				<strong>{{ $transChoice('marketing.nextDate', 2) }}</strong>
				<div class="filters badge">
					{{ $transChoice('messages.filter', 1) }} <Icon icon="sliders ml-1"></Icon>
				</div>
			</div>
			<Box v-for="(packet, key) in monthCurrent.packets"
				:key="[packetType, selectedMonthId, packet.id].join('.')"
				:packet="packet"
				:layout="packets.length === 1 ? null : 'small'"
				:startOpen="key === 0"
				@selectPacket="selectPacket"></Box>
		</template>
		<div class="w-100 text-center" v-else>
			<h5>{{ availablePackets.length }} {{ $transChoice('messages.'+packetType, availablePackets.length) }}</h5>
			<button type="button" class="btn btn-primary" @click="showPacketDetails">{{ $trans('messages.showAll') }}</button>
		</div>
	</div>
</template>

<script>
import Box from "./_partials/Box";
import Loading from "./Loading";
import Icon from "./_partials/Icon";

export default {
	name: 'Packets',
	components: {Icon, Box, Loading},
	props: {
		packetType: {
			type: String,
			required: true,
			validator(value) {
				return ['packet', 'event', 'moment'].includes(value);
			}
		}
	},
	inject: ['schoolId', 'category_id', 'age_group', 'rangePackets', 'showPackets', 'filter_name'],
	data(){
		return {
			loaded: false,
			packets: {},
			selectedMonthId: 0
		}
	},
	created(){
		document.addEventListener(['show', this.packetType].join('-'), (e) => {
			if(typeof this.packets[e.detail.productId] !== 'undefined')
				this.selectPacket(this.packets[e.detail.productId])
			else{
				// alert(this.$trans('productNotFound'))
			}
		});

		// TODO: instead of looking at the DB, download the public pages when the widget is opened
		this.$requestGET(['school-database', this.schoolId, this.packetType+'s'].join('/'))
		.then(r => {
			this.packets = r.data
			this.loaded = true
			document.dispatchEvent(new CustomEvent('loadedImproovingProducts-'+this.packetType))
		})
	},
	computed: {
		availablePackets(){
			return Object.values(this.packets).reduce((acc, packet) => {
				if(
					packet.sellable_online && parseFloat(packet.price) > 0
					&& (this.category_id === null || this.packetType === 'event' || (typeof packet.activities !== 'undefined' && packet.activities.length && typeof packet.activities.find(el => el.category_id == this.category_id) !== 'undefined'))
					&& (this.age_group === null
						|| (
							(packet.max_age === null || packet.max_age <= this.age_group.max_age)
							&& (packet.min_age === null || packet.min_age >= this.age_group.min_age)
						)
					)
					&& (this.filter_name === null
						|| packet.name.toLowerCase().includes(this.filter_name.toLowerCase())
					)
					&& (
						// Make sure the dates fit with our search (TODO: disable when loading a specific packet)
						(this.$momentItem(packet.start_date).isSameOrAfter(this.$momentItem()) || typeof packet.variations.find(variation => this.$momentItem(variation.start_date).isSameOrAfter(this.$momentItem())) !== 'undefined')
						&& (this.rangePackets.start === null || this.$momentItem(packet.end_date).isSameOrAfter(this.$momentItem(this.rangePackets.start)) || typeof packet.variations.find(variation => this.$momentItem(variation.end_date).isSameOrAfter(this.$momentItem(this.rangePackets.start))) !== 'undefined')
						&& (this.rangePackets.end === null || this.$momentItem(packet.start_date).isSameOrBefore(this.$momentItem(this.rangePackets.end)) || typeof packet.variations.find(variation => this.$momentItem(variation.start_date).isSameOrBefore(this.$momentItem(this.rangePackets.end))) !== 'undefined')
					)
				){

					if(typeof packet.variations !== 'undefined')
						packet.variations = packet.variations.filter(variation => {
							// TODO: make more elegant
							if(typeof packet.packet_prices !== 'undefined' && typeof variation.packet_prices == 'undefined')
								variation.packet_prices = JSON.parse(JSON.stringify(packet.packet_prices));

							return this.$momentItem(variation.start_date).isAfter(this.$momentItem());
						});

					acc.push(packet)
				}

				return acc;
			}, []);
		},
		availablePacketsByMonth(){
			return Object.values(this.availablePackets.reduce((acc, packet) => {
				if(this.$momentItem(packet.start_date).isSameOrAfter(this.$momentItem())){
					let year_month = this.$momentItem(packet.start_date).format('YYYY-MM')+'-01';
					if(typeof acc[year_month] === 'undefined')
						acc[year_month] = {
							year_month: year_month,
							packets: []
						};

					acc[year_month].packets.push(packet);
				}

				if(typeof packet.variations !== 'undefined')
					packet.variations.forEach(variation => {
						let year_month_variation = this.$momentItem(variation.start_date).format('YYYY-MM')+'-01';
						if(typeof acc[year_month_variation] === 'undefined'){
							acc[year_month_variation] = {
								year_month: year_month_variation,
								packets: []
							};

							acc[year_month_variation].packets.push(packet);
						}
						else if(typeof acc[year_month_variation].packets.find(p => p.id == variation.original_id) === 'undefined'){
							acc[year_month_variation].packets.push(packet);
						}
					});

				return acc;
			}, {})).sort((a, b) => {
				return a.year_month > b.year_month ? 1 : -1;
			}).reduce((acc2, month_object) => {
				let month_to_consider = this.$momentItem(month_object.year_month),
					packets_of_month = JSON.parse(JSON.stringify(month_object.packets)).reduce((acc2, packet) => {
						if(typeof packet.variations !== 'undefined')
							packet.variations = packet.variations.filter(el => {
								return this.$momentItem(el.start_date).isSameOrBefore(month_to_consider, 'month')
									&& this.$momentItem(el.end_date).isSameOrAfter(month_to_consider, 'month');
							});
						acc2.push(packet);
						return acc2;
					}, []);
				acc2.push({
					year_month: month_object.year_month,
					packets: packets_of_month
				});
				return acc2;
			}, []);
		},
		hasAvailablePackets(){
			return Object.values(this.availablePacketsByMonth).length;
		},
		monthPrevious(){
			return this.hasAvailablePackets && typeof Object.values(this.availablePacketsByMonth)[this.selectedMonthId - 1] !== 'undefined' ?
				Object.values(this.availablePacketsByMonth)[this.selectedMonthId - 1] : null;
		},
		monthCurrent(){
			return this.hasAvailablePackets && typeof Object.values(this.availablePacketsByMonth)[this.selectedMonthId] !== 'undefined' ?
				Object.values(this.availablePacketsByMonth)[this.selectedMonthId] : null;
		},
		monthNext(){
			return this.hasAvailablePackets && typeof Object.values(this.availablePacketsByMonth)[this.selectedMonthId + 1] !== 'undefined' ?
				Object.values(this.availablePacketsByMonth)[this.selectedMonthId + 1] : null;
		},
	},
	methods:{
		selectSection(){
			this.$emit('selectSection', this.packetType)
		},
		showPacketDetails(){
			this.$emit('showPacketDetails', true)
		},
		showInfo(packet){
			if(typeof packet.show_info === 'undefined')
				packet.show_info = true;
			else
				delete packet.show_info;

			Object.values(this.packets).forEach(db_packet => {
				if(db_packet.id !== packet.id)
					delete db_packet.show_info;
			});
		},
		selectPacket(packet){
			this.$emit('selectPacket', packet)
		}
	}
}
</script>

