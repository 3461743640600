<template>
	<form :id="$cartFormId" class="shopping-cart" @submit="this.$completeCheckout">
		<h3>{{ $trans('messages.shoppingCart') }}</h3>
		<ul v-if="$cartLessons.value.length" class="list-unstyled">
			<li v-for="item in $cartLessons.value" :key="['cart', 'lessons', item.id].join('.')" class="mb-3">
				<Lesson :lesson="item" :onlyDescription="true"></Lesson>
			</li>
		</ul>
		<ul v-if="$cartLessonsNoteacher.value.length" class="list-unstyled">
			<li v-for="item in $cartLessonsNoteacher.value" :key="['cart', 'lessons_no_teacher', item.id].join('.')" class="mb-3">
				<Lesson :lesson="item" :onlyDescription="true"></Lesson>
			</li>
		</ul>
		<template v-for="(items, category) in $cart.value">
			<div v-if="!category.includes('lesson') && !category.includes('service') && items.length" :key="['cart', category].join('.')">
				<ul class="list-unstyled">
					<li v-for="item in items" :key="['cart', category, item.id].join('.')" class="mb-3">
						<Product :product="item" :onlyDescription="true">
							({{ [item.selected || null, item.price || null].join(' x ') }})  <span @click="removeOneItem(item)" class="text-danger">{{ $trans('messages.remove') }}</span> <span @click="addOneItem(item)">{{ $trans('messages.add') }}</span>
							<SelectPupils v-if="!category.includes('gift') && showCartReview"
								:input-name="`pupils[packet][${item.id}]`"
								:pupils-number="item.selected"
								:selection-is-required="true"></SelectPupils>
						</Product>
					</li>
				</ul>
			</div>
		</template>

		<div v-if="$checkoutAsNewUserInfo.value !== null" class="mb-2">
			<h3>{{ $transChoice('messages.userDetail', 1) }}</h3>
			<div class="input-group input-group-sm mb-1">
				<input name="email" :placeholder="$transChoice('form_db.email', 1)" :class="`form-control ${typeof $lastCartMessage.value.email !== 'undefined' ? 'is-invalid' : ''}`" v-model="$checkoutAsNewUserInfo.value.email"  />
				<div v-if="typeof $lastCartMessage.value.email !== 'undefined'" class="invalid-feedback">{{ $lastCartMessage.value.email }}</div>
			</div>
			<div class="input-group input-group-sm mb-1">
				<input name="last_name" :placeholder="$transChoice('form_db.first_name', 1)" :class="`form-control ${typeof $lastCartMessage.value.first_name !== 'undefined' ? 'is-invalid' : ''}`" v-model="$checkoutAsNewUserInfo.value.first_name"  />
				<div v-if="typeof $lastCartMessage.value.first_name !== 'undefined'" class="invalid-feedback">{{ $lastCartMessage.value.first_name }}</div>
			</div>
			<div class="input-group input-group-sm mb-1">
				<input name="first_name" :placeholder="$transChoice('form_db.last_name', 1)" :class="`form-control ${typeof $lastCartMessage.value.last_name !== 'undefined' ? 'is-invalid' : ''}`" v-model="$checkoutAsNewUserInfo.value.last_name"  />
				<div v-if="typeof $lastCartMessage.value.last_name !== 'undefined'" class="invalid-feedback">{{ $lastCartMessage.value.last_name }}</div>
			</div>
			<div class="input-group input-group-sm mb-1">
				<input name="phone" :placeholder="$transChoice('form_db.phone', 1)" :class="`form-control ${typeof $lastCartMessage.value.phone !== 'undefined' ? 'is-invalid' : ''}`" v-model="$checkoutAsNewUserInfo.value.phone"  />
				<div v-if="typeof $lastCartMessage.value.phone !== 'undefined'" class="invalid-feedback">{{ $lastCartMessage.value.phone }}</div>
			</div>
			<div class="input-group input-group-sm mb-1">
				<input name="password" :placeholder="$transChoice('form_db.password', 1)" :class="`form-control ${typeof $lastCartMessage.value.password !== 'undefined' ? 'is-invalid' : ''}`" v-model="$checkoutAsNewUserInfo.value.password"  />
				<input type="hidden" name="password_confirmation" :placeholder="$transChoice('form_db.password', 1)" class="form-control" v-model="$checkoutAsNewUserInfo.value.password"  />
				<div v-if="typeof $lastCartMessage.value.password !== 'undefined'" class="invalid-feedback">{{ $lastCartMessage.value.password }}</div>
			</div>
		</div>

		<template v-if="$paymentIntentId.value !== null">
			<div class="d-grid my-2">
				<span class="btn btn-improoving" @click="this.$completeCheckout">
					{{ $trans('messages.submit') }}
				</span>
			</div>
		</template>
		<StripeCheckout v-else-if="currentCheckoutStep === 'payment'"></StripeCheckout>
	</form>
</template>

<script>

import CheckoutMixin from "../mixins/checkout_steps";
import Lesson from "./product_cards/Lesson";
import Product from "./Product";
import SelectPupils from "./SelectPupils";
import StripeCheckout from "./StripeCheckout";

export default {
	name: 'Cart',
	components: {Lesson, Product, SelectPupils, StripeCheckout},
	mixins: [CheckoutMixin],
	props: {},
	created(){},
	computed: {
	},
	methods: {
		removeOneItem(item){
			if(item.selected <= 1)
				this.$removeFromCart(item).then(() => {
					this.$checkoutCart();
				})
			else{
				item.selected -= 1
				this.$updateObject(item).then(() => {
					this.$checkoutCart();
				})
			}
		},
		addOneItem(item){
			this.$addToCart(item).then(() => {
				this.$checkoutCart();
			})
		}
	}
}
</script>