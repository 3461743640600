<style>
#improoving-widget #order-summary{
	filter: drop-shadow(0px 4px 4px #242A5C);
	background-color: rgba(211, 229, 227, 1);
	padding: 5px 10px;
	border-radius: 5px;
}
#improoving-widget #order-summary .price:before{
	content: "€ ";
	font-size: small;
}
#improoving-widget #order-summary ul,
#improoving-widget #order-summary p{
	padding: 0;
	font-size: small;
	font-style: italic;
	text-align: left;
}
#improoving-widget #order-summary p{
	margin-bottom: 0.5em;
}

#improoving-widget #order-summary ul > li{
	display: inline-block;
}
#improoving-widget #order-summary ul > li + li:before{
	content: ", ";
}
#improoving-widget #order-summary > div{
	display: flex;
	justify-content: space-between;
}
#improoving-widget #order-summary h1,
#improoving-widget #order-summary h2,
#improoving-widget #order-summary h3,
#improoving-widget #order-summary h4{
	display: flex;
	justify-content: space-between;
	text-transform: uppercase;
	font-weight: bold;
	margin-bottom: 0;
}
#improoving-widget #order-summary > h1,
#improoving-widget #order-summary > h3{
	color: rgba(238, 139, 82, 1);
	margin-bottom: 10px;
}
#improoving-widget #order-summary > h1{	font-size: 1.2em; }
#improoving-widget #order-summary > h2{	font-size: 1.1em; }
#improoving-widget #order-summary > h3{	font-size: 1em; }
#improoving-widget #order-summary > h4{	font-size: 0.9em; }

</style>

<template>
	<div>
		<div v-if="showExpanded" class="text-left">
			<h3>Riepilogo</h3>
			<ul>
				<li>{{ product.name }}</li>
				<li v-if="participantsTotal > 0" class="mb-0">{{ [participantsTotal, isGiftCard ? $transChoice('messages.product', participantsTotal) : $transChoice('messages.pupil', participantsTotal)].join(' ') }}</li>
				<li v-if="selectedDates.length > 0">
					<ul class="list-unstyled">
						<li v-for="date in selectedDates" :key="['summary-ext', 'date', date.date].join('.')">{{ $momentItemFormatted(date.date) }}</li>
					</ul>
				</li>
				<li v-if="typeof selectedRooms !== 'undefined' && selectedRooms !== null && selectedRooms.length">
					Sistemazione:
					<br/>
					<ul class="list-unstyled">
						<template v-for="room in selectedRooms" :key="['summary-ext', 'room', room.id].join('.')">
							<li v-if="typeof room.selected !== 'undefined' && room.selected">{{room.selected}}x <strong>{{room.name}}</strong></li>
							<template	v-for="size in room.sizes_selected"
								:key="['summary-ext', 'room', room.id, 'size', size.id].join('.')"
								v-else>
								<li v-if="typeof size.selected !== 'undefined' && size.selected">{{size.selected}}x <strong>{{room.name}}</strong> {{ size.name }}</li>
							</template>
						</template>
					</ul>
				</li>
			</ul>
			<hr/>
			<h3>Servizi Aggiuntivi Inclusi</h3>
			<ul v-if="typeof selectedServices !== 'undefined' && selectedServices !== null && selectedServices.length">
				<li v-for="service in selectedServices" :key="['summary-ext', 'service', service.id].join('.')">
					<strong>{{ service.name }}</strong> x{{ Object.values(service.selected_pupils).length }}
				</li>
			</ul>
			<p v-else>Nessun servizio aggiunto</p>
			<hr />
			<!--
			<h3>Cosa Troverai</h3>
			<p>Inserire del testo specifico</p>
			-->

			<!-- Fare in modo che il link sia corretto -->
			<Checkbox @changeChecked="acceptPolicies"
				@changeNotChecked="refusePolicies"
				:classes="policiesAccepted ? '' : 'text-danger'"
				:checked="policiesAccepted"
				:text="$transChoice('checkout.acceptGeneralSaleConditions', 1, [['rules', '/legal/rules/'+schoolId]]).replace(/(<([^>]+)>)/gi, '')"></Checkbox>

			<div class="row my-3">
				<!--
				<div class="col d-grid">
					<button class="btn btn-success" type="button">
						Invita Amici
					</button>
				</div>
				-->
				<div class="col d-grid px-5">
					<button class="btn btn-primary" type="button" @click="$emit('proceedToBooking')" :disabled="!policiesAccepted">
						Prenota Ora
					</button>
				</div>
			</div>
		</div>
		<section id="order-summary">
			<h3>Riepilogo</h3>
			<h2>
				<span>{{ product.name }}</span>
				<span v-if="productTotalPrice > 0" class="price">{{ productTotalPrice.toFixed(2) }}</span>
			</h2>
			<p v-if="participantsTotal > 0">{{ [participantsTotal, isGiftCard ? $transChoice('messages.product', participantsTotal) : $transChoice('messages.pupil', participantsTotal)].join(' ') }}</p>
			<p v-else>{{ isGiftCard ? "Seleziona la quantità" : "Seleziona il numero dei partecipanti"}}</p>
			<ul v-if="typeof selectedRooms !== 'undefined' && selectedRooms !== null && selectedRooms.length"
				class="list-unstyled">
				<template v-for="room in selectedRooms" :key="['summary', 'room', room.id].join('.')">
					<li v-if="typeof room.selected !== 'undefined' && room.selected">{{room.selected}}x <strong>{{room.name}}</strong></li>
					<template	v-for="size in room.sizes_selected"
						:key="['summary', 'room', room.id, 'size', size.id].join('.')"
						v-else>
						<li v-if="typeof size.selected !== 'undefined' && size.selected">{{size.selected}}x <strong>{{room.name}}</strong> {{ size.name }}</li>
					</template>
				</template>
			</ul>
			<h2>
				<span>Servizi Aggiuntivi</span>
				<span v-if="!isNaN(selectedServicesTotal) && selectedServicesTotal !== 0" class="price">{{ selectedServicesTotal.toFixed(2) }}</span>
			</h2>
			<ul v-if="typeof selectedServices !== 'undefined' && selectedServices !== null && selectedServices.length"
				class="list-unstyled">
				<li v-for="service in selectedServices" :key="['summary', 'service', service.id].join('.')">{{ service.name }} x{{ Object.values(service.selected_pupils).length }}</li>
			</ul>
			<p v-else>Arricchisci la tua prenotazione con servizi aggiuntivi che lo renderanno speciale e più comodo.</p>
			<template v-if="orderTotalPrice > 0">
				<h1 class="mb-2">
					<span>{{ $transChoice('messages.total', 1) }}</span>
					<span class="price">{{ orderTotalPrice.toFixed(2) }}</span>
				</h1>
				<h4>
					<span>Acconto</span>
					<span class="price">{{ depositAmount }}</span>
				</h4>
				<p>Richiesto in fase di pagamento</p>
			</template>
		</section>
	</div>
</template>

<script>
import Checkbox from "./Checkbox";
export default{
	name: 'OrderSummary',
	components: {Checkbox},
	props: {
		product: {
			type: Object,
			required: true
		},
		showExpanded: {
			type: Boolean,
			default: false
		}
	},
	inject: ['schoolId', 'selectedRooms', 'roomSelectionIsComplete', 'participants', 'participantsTotal', 'productTotalPrice', 'selectedDates', 'selectedServices', 'selectedServicesTotal', 'orderTotalPrice', 'isPacket', 'isEvent', 'isGiftCard'],
	data(){
		return {
			show_variations: false,
			policiesAccepted: false
		}
	},
	computed: {
		depositAmount(){
			return ((this.productTotalPrice + this.selectedServicesTotal) * this.$depositPercentage).toFixed(2);
		}
	},
	watch: {
		policiesAccepted(newValue){
			if(newValue)
				this.$emit('acceptPolicies');
			else
				this.$emit('refusePolicies');

		}
	},
	methods: {
		acceptPolicies(){
			this.policiesAccepted = true;
		},
		refusePolicies(){
			this.policiesAccepted = false;
		},
	}
}
</script>