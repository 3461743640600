<template>
	<div class="flow-packets">
		<template v-if="selectedPacket !== null">
			<Product :product="selectedPacket"
				@resetSelection="selectedPacket = null"></Product>
		</template>
		<template v-else>
			<span @click="returnToWidget"><Icon icon="arrow-left-circle mr-1 improoving-text"></Icon> {{ $trans('messages.back') }}</span>
			<h2 class="mt-5 text-center">{{ $trans('marketing.bookNow') }}</h2>
			<p class="text-center">{{ $trans('marketing.bookNowExplanation') }}</p>

			<hr class="dashed" />

			<div v-if="allowDateSelection" class="w-100 text-center">
				<template v-if="!showPackets">
					<p>
						<strong>{{ $trans('select_dates') }}</strong>
						<br/>
						{{ $trans('select_dates_explanations')}}
					</p>

					<DatePicker v-model="rangePackets" is-range />
				</template>

				<p class="my-3">
					<strong>{{ $trans('select_dates_selected') }}:</strong>
					<br/>
					{{ $momentItemFormattedInterval(rangePackets.start, rangePackets.end) }}
					<template v-if="showPackets">
						<br/>
						<a href="#" @click="showPackets = false">{{ $trans('select_dates_change_selection') }}</a>
					</template>
				</p>
			</div>

			<Packets :packet-type="packetType"
				@selectPacket="selectProduct"
				@showPacketDetails="showPackets = true"></Packets>
		</template>
	</div>
</template>

<script>
import { DatePicker } from 'v-calendar'
import Icon from "./_partials/Icon";
import Packets from "./Packets";
import Product from "./Product";
import CheckoutMixin from "../mixins/checkout_steps"
import {computed} from "vue";

export default {
	name: 'FlowPackets',
	mixins: [CheckoutMixin],
	components: {DatePicker, Icon, Packets, Product},
	props: {
		packetType: {
			type: String,
			default: 'packet'
		},
		allowDateSelection: {
			type: Boolean,
			default: false
		}
	},
	inject: ['category_id', 'pupils_num', 'age_group', 'level_group', 'showSectionEventsOnly'],
	created(){
		document.addEventListener(['select', 'packet'].join('-'), (e) => {
			console.log("Received the following packet", e.detail.packet);
			this.selectProduct(JSON.parse(e.detail.packet));
		});
	},
	data(){
		return {
			selectedPacket: null,
			showPackets: true,
			rangePackets: {
				start: this.$momentItem().add(1, 'day').toDate(),
				end: this.$momentItem().add(this.allowDateSelection ? 3 : 12, 'months').toDate()
			}
		}
	},
	provide(){
		return {
			rangePackets: computed(() => this.rangePackets),
			showPackets: computed(() => this.showPackets)
		}
	},
	watch:{
		rangePackets(){
			this.showPackets = false;
		}
	},
	computed: {
		showCalendar(){
			return this.start_date !== null && this.end_date !== null;
		},
		calendarAttributes(){
			return [];
/*
			let attributes = [],
				object = {
					highlight: {
						start: { fillMode: 'outline' },
						base: { fillMode: 'light' },
						end: { fillMode: 'light' },
					},
					dates: { start: this.$momentItem(this.product.start_date).toDate(), end: this.$momentItem(this.product.end_date).toDate() }
				};

			attributes.push(object)
			if(typeof this.product.variations !== 'undefined')
				this.product.variations.forEach(variation => {
					let object2 = {
						highlight: {
							start: { fillMode: 'outline' },
							base: { fillMode: 'light' },
							end: { fillMode: 'light' },
						},
						dates: { start: this.$momentItem(variation.start_date).toDate(), end: this.$momentItem(variation.end_date).toDate() }
					};

					attributes.push(object2)
				});

			return attributes;

 */
		},
	},
	methods: {
		selectProduct(packet){
			this.selectedPacket = packet
		}
	}
}
</script>