<style lang="scss">
#improoving-widget {
	.select-pupils-table .price {
		padding-left: 0.5em;
	}
}
</style>

<template>
	<div>
		<table :class="`table table-fixed table-xs border-0 select-pupils-table ${loading ? 'hidden' : '' }`">
			<tbody v-for="n in pupilsNumber" :key="`table-key.${n}`">
			<tr v-if="selectedRoomsDistribution.length && typeof selectedRoomsDistribution[n-1] !== 'undefined' && (typeof selectedRoomsDistribution[n-2] === 'undefined' || selectedRoomsDistribution[n-1].name !== selectedRoomsDistribution[n-2].name)">
				<td colspan="2">
					<strong>{{ selectedRoomsDistribution[n-1].name }}</strong>
				</td>
			</tr>
			<tr>
				<td class="text-center">
					{{ [$transChoice('messages.pupil', 1),n].join(' ') }}
					<div v-if="!$checkoutAsNewUser.value && enableQuickCreation" class="d-grid my-2">
						<span class="btn btn-sm btn-outline-dark" @click="togglePupilCreation(n)">{{ new_pupils.includes(n) ? $trans('checkout.useRelatives') : $trans('checkout.addRelative') }}</span>
					</div>
				</td>
				<td>
					<em v-if="n === 1 && $checkoutAsNewUser.value">{{ $trans('checkout.pupilIsPayingUser') }}</em>
					<select	v-else-if="pupilsOfCustomer.length && !new_pupils.includes(n)"
							:name="`${inputName}[${n}][id]`"
							v-model="pupil_values[n]"
							@change="selectPupil(n, pupil_values[n])"
							:class="`form-control input-sm ${selectionIsRequired || enableQuickCreation ? 'required' : ''}`">
						<option v-for="pupil in pupilsOfCustomer"
								:key="`select-pupil-${pupil.id}`"
								:value="pupil.id">{{ pupil.label }}</option>
					</select>
					<template v-else-if="!loading">
						<div :class="`input-group input-group-sm ${selectionIsRequired ? 'required' : ''}`">
							<input :name="`${inputName}[${n}][first_name]`" :placeholder="$transChoice('form_db.first_name', 1)" class="form-control" />
						</div>
						<div :class="`input-group input-group-sm ${selectionIsRequired ? 'required' : ''}`">
							<input :name="`${inputName}[${n}][last_name]`" :placeholder="$transChoice('form_db.last_name', 1)" class="form-control" />
						</div>
					</template>

					<!-- Associate each participant to its room -->
					<template v-if="typeof selectedRoomsDistribution[n-1] !== 'undefined'">
						<input type="hidden"
							:name="`${inputName}[${n}][rental][${selectedRoomsDistribution[n-1].type}][${selectedRoomsDistribution[n-1].id}][id]`"
							:value="selectedRoomsDistribution[n-1].id" />
						<input type="hidden"
							:name="`${inputName}[${n}][rental][${selectedRoomsDistribution[n-1].type}][${selectedRoomsDistribution[n-1].id}][number]`"
							:value="selectedRoomsDistribution[n-1].number" />
					</template>

					<Checkbox v-for="service in servicesToAssociate"
						:key="['add-service', service.id, n].join('.')"
						:checked="typeof service.selected_pupils !== 'undefined' && typeof service.selected_pupils[n] !== 'undefined'"
						:name="['service', 'pupil', service.id, n].join('_')"
						:value="true"
						:disabled="typeof service.pivot !== 'undefined' && service.pivot.selection_type.includes('not_removable')"
						@changeChecked="addService(service, n)"
						@changeNotChecked="removeService(service, n)">
						<strong>{{ service.name }}</strong>
						<span v-if="typeof service.pivot === 'undefined' || !service.pivot.selection_type.includes('included') || showPriceOfIncludedServices" class="price pl-1">{{ $formattedPrice($servicePrice(service, 1, priceBaseForServices)) }}</span>
					</Checkbox>
				</td>
			</tr>
			</tbody>
		</table>
		<div class="d-grid my-2">
			<span class="btn btn-outline-dark" @click="register = !register">
				Devi registrare nuovi partecipanti?
			</span>
		</div>
		<template v-if="register">
			<div class="input-group input-group-sm mb-1">
				<input name="new_relative_first_name" :placeholder="$transChoice('form_db.first_name', 1)" class="form-control" v-model="new_relative.first_name" />
			</div>
			<div class="input-group input-group-sm mb-1">
				<input name="new_relative_last_name" :placeholder="$transChoice('form_db.last_name', 1)" class="form-control"  v-model="new_relative.last_name" />
			</div>
			<div class="input-group input-group-sm mb-1">
				<input name="new_relative_birthday" :placeholder="$transChoice('form_db.birthday', 1)" class="form-control"  v-model="new_relative.birthday" />
			</div>
			<div class="input-group input-group-sm mb-1">
				<select class="custom-select custom-select-sm mb-1" name="new_relative_assessment" v-model="new_relative.assessment">
					<option selected :value="null">Selezionare il livello</option>
					<option v-for="(assessment, assessment_key) in availableAssessments"
						:key="['assessment', assessment_key].join('.')"
						:value="`levelgroup_${assessment_key}`">{{ assessment }}</option>
				</select>
			</div>
			<div class="input-group input-group-sm mb-1">
				<Checkbox v-for="(language, language_key) in availableLanguagesSpoken"
					:key="['language', language_key].join('.')"
					:checked="false"
					:name="['language', language_key].join('_')"
					:value="true"
					@changeChecked="new_relative.languages.push(language_key)"
					@changeNotChecked="new_relative.languages.splice(new_relative.languages.indexOf(language_key))">
					<strong>{{ $trans('translations.'+language) }}</strong>
				</Checkbox>
			</div>
			<div class="d-grid my-2">
			<span :class="`btn ${canCreateRelative ? 'btn-dark' : 'btn-outline-dark'} `" @click="registerNew" :disabled="!canCreateRelative">
				{{ $trans('messages.create') }}
			</span>
			</div>
		</template>
	</div>
</template>

<script>
import Checkbox from "./_partials/Checkbox";
export default {
	name: 'SelectPupils',
	components: {Checkbox},
	props: {
		inputName: {
			type: String,
			default: 'pupils_selected'
		},
		pupilsNumber: {
			type: Number,
			default: 1
		},
		selectionIsRequired: {
			type: Boolean,
			default: false
		},
		enableQuickCreation: {
			type: Boolean,
			default: false
		},
		servicesToAssociate: {
			type: Array,
			default(){
				return [];
			}
		},
		priceBaseForServices: {
			type: Number,
			default: 0
		},
		selectedRoomsDistribution: {
			type: Array,
			default(){
				return []
			}
		}
	},
	inject: ["showPriceOfIncludedServices", "availableAssessments", "availableLanguagesSpoken"],
	data(){
		return {
			loading: false,
			pupilsOfCustomer: [],
			pupil_values: {},
			new_pupils: [],
			pupil_models: {},
			new_relative: {
				first_name: null,
				last_name: null,
				birthday: null,
				assessment: null,
				languages: []
			},
			register: false
		}
	},
	created(){
		this.fetchPupils();
	},
	watch: {
		pupilsNumber(newValue){
			this.resetPupils(newValue).then(() => {
				this.$emit('updateQuantity', newValue);
			})
		},
		register(newValue){
			if(!newValue){
				this.new_relative.first_name = null;
				this.new_relative.last_name = null;
				this.new_relative.assessment = null;
				this.new_relative.assessment = null
				this.new_relative.languages = [];

			}
		}
	},
	computed:{
		canCreateRelative(){
			return this.new_relative.last_name !== null  && this.new_relative.last_name.trim() !== ''
				&& this.new_relative.first_name !== null && this.new_relative.first_name.trim() !== ''
				&& this.new_relative.birthday !== null && this.new_relative.birthday.trim() !== ''
				&& this.new_relative.assessment !== null
				&& this.new_relative.languages.length;
		}
	},
	methods: {
		fetchPupils() {
			if(!this.$checkoutAsNewUser.value)
				this.$getUserRelatives()
				.then(response => {
					this.$loggedUser.value.label = this.$personIdentifier(this.$loggedUser.value);

					let pupils = [{
						label: this.$personIdentifier(this.$loggedUser.value),
						id: this.$loggedUser.value.person_id,
					}];

					this.pupilsOfCustomer = Object.values(response).reduce((acc, relative) => {
						console.log('RELATIVE', relative)
						relative.label = this.$personIdentifier(relative)
						acc.push(relative);
						return acc
					}, pupils).sort((a, b) => {
						return a.label > b.label ? 1 : -1;
					});

				});
		},
		resetPupil(key, notify = false){
			if(notify !== false)
				this.$nextTick(() => {
					// This is set BEFORE resetting the pupil or it won't trigger
					alert(this.$trans('checkout.pupilIsDuplicate'));
				});
			this.pupil_values[key] = null;
		},
		resetPupils(quantity = null){
			return new Promise(resolve => {
				let counter = Math.max(...Object.keys(this.pupil_values)),
					limit = quantity !== null ? quantity : this.pupilsNumber;
				do{
					if(this.new_pupils.includes(counter))
						this.new_pupils.splice(this.new_pupils.indexOf(counter), 1);

					if(counter > limit){
						delete this.pupil_values[counter];
					}
					else if(typeof this.pupil_values[counter] === 'undefined')
						this.resetPupil(counter);
					counter--;
				} while(counter > 0);
				resolve(true);
			})
		},
		selectPupil(n, value){
			if(Object.values(this.pupil_values).filter(id => id === value).length > 1){
				this.resetPupil(n, true);
			}
			else
				this.$emit('selectPupil', {
					number: n,
					pupil_id: value
				});
		},
		togglePupilCreation(n){
			if(this.new_pupils.includes(n))
				this.new_pupils.splice(this.new_pupils.indexOf(n), 1);
			else{
				this.resetPupil(n);
				this.new_pupils.push(n);
			}
		},
		registerNew(){
			if(this.canCreateRelative)
				this.$storeRelative(this.new_relative.first_name, this.new_relative.last_name, this.new_relative.birthday, this.new_relative.languages, {
					category_id: 1,
					level_id: this.new_relative.assessment
				})
				.then(() => {
					this.fetchPupils();
					this.register = false;
				})
			else
				alert(this.$trans('messages.formIsNotValid'))
		},
		addService(service, n){
			this.$emit('addService', {
				service: service,
				pupil_num: n
			})
		},
		removeService(service, n){
			this.$emit('removeService', {
				service: service,
				pupil_num: n
			})
		}
	}
}
</script>