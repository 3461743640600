<style lang="scss">
#improoving-widget {
	.selectable-date {
		cursor: pointer;
	}

	.arrow-left-circle,
	.bi-arrow-left-circle {
		cursor: pointer;
	}

	.product {
		.picture-container {
			margin: 0;
			padding: 0;
			min-height: 150px;

			> h1,
			h2 {
				margin: 0 -20px;
				padding-bottom: 20px;
				background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0));
				color: black;
			}
		}

		.select-participants {
			display: flex;
			position: relative;
			max-width: 100%;

			> * {
				flex-basis: 0;
				flex-grow: 1;
				text-align: center;
				padding: 10px;
				overflow: hidden;

				&:nth-of-type(n+2) {
					border-left: 1px solid currentColor;
				}
			}

			em {
				font-size: small;
			}

			> *:nth-of-type(n+2) {
				border-left: 1px solid currentColor;
			}
		}

		.select-participants [class^="bi"],
		.select-participants strong,
		.rental-selector [class^="bi"],
		.rental-selector strong {
			cursor: pointer;
			font-size: 1.5em;
		}

		.rental-selector strong {
			width: 1.5em;
			text-align: center;
		}

		.rental-type {
			text-align: left;

			ul {
				border: 1px dashed black;
				border-radius: 5px;
				margin-bottom: 5px;
				padding-bottom: 0;

				li {
					align-items: center;
				}
			}
		}

		.service-box {
			border: 1px dashed black;
			border-radius: 5px;
			margin-bottom: 5px;
			text-align: left;
			padding: 5px;
		}

		.quantity-selector > * {
			padding: 0 5px;
		}

		.service-list-item {
			padding-left: 20px;
			padding-right: 20px;

			.checkbox {
				margin-left: -20px;
			}

			&:last-child {
				border-bottom: none;
			}
		}

		.payer-details{
			.utility{


				visibility: hidden;
				display: none;
			}
		}

	}
}

</style>

<template>
	<div class="product">
		<span v-if="isEvent || isPacket || isGiftCard" @click="previousStep"><Icon icon="arrow-left-circle mr-1 improoving-text"></Icon> {{ $trans('messages.back') }}</span>

		<div v-if="typeof product.image !== 'undefined'" class="text-center">
			<div class="picture-container" :style="`background-image: url('${product.image.url}'); background-size: cover;`">
			</div>
		</div>


		<slot></slot>

		<template v-if="!onlyDescription">
			<nav class="nav nav-pills nav-justified checkout-steps">
				<span :class="`nav-item nav-link active`">{{ isGiftCard ? "Dettagli Prodotto" : "Dettagli Esperienza" }}</span>
				<span v-if="isPacket || isEvent" :class="`nav-item nav-link ${step >= 4 ? 'active' : ''}`">Partecipanti</span>
				<span :class="`nav-item nav-link ${step >= 6 ? 'active' : ''}`">Metodo di pagamento</span>
			</nav>

			<h2 class="text-center">{{ $localizedAttribute(product, 'name') }}</h2>
			<div class="text-center">
				<h3 v-if="isPacket || isEvent" class="dates-of-product improoving-text">{{ $momentItem(product.end_date).diff(product.start_date, 'days') > 0 ? $momentItemFormattedInterval(product.start_date, product.end_date, 'DD MMM') : $momentItemFormatted(product.start_date, 'DD MMM') }}</h3>
				<a class="mimic-link" href="#" @click="$emit('resetSelection')">{{ isGiftCard ? "Cambia Prodotto" : "Cambia Esperienza" }}</a>
				<hr class="dotted" />
				<template v-if="step === 0">
					<p>{{ $localizedAttribute(product, 'description') }}</p>
					<h4 class="text-left">{{ isGiftCard ? $transChoice('messages.quantity', 2) : $transChoice('messages.pupil', 2) }}</h4>
					<div class="select-participants mb-3">
						<div v-for="(item, key) in participants" :key="['participant', key].join('.')">
							<template v-if="key !== 'full_price'">
								<h5 class="mb-0">{{ [product.packet_prices.find((el) => el.id == key).age_min || 0, product.packet_prices.find((el) => el.id == key).age_max || 99].join(' - ') +' '+$transChoice('messages.year', 2) }}</h5>
								<!-- <em class="text-center">{{ $trans('marketing.'+key+'Description') }}</em> -->
							</template>
							<template v-else-if="Object.values(participants).length > 1">
								<h5 class="mb-0">Standard</h5>
							</template>
							<div class="d-flex justify-content-between">
								<Icon icon="dash-circle" @click="participants[key] = Math.max(participants[key]-1, 0)"></Icon>
								<strong>{{ participants[key] }}</strong>
								<Icon icon="plus-circle" @click="participants[key]++"></Icon>
							</div>
						</div>
					</div>
				</template>
				<template v-if="step === 1">
					<template v-if="isEvent">
						<h4 class="text-left">Sistemazione</h4>
						<div v-for="room in availableRooms" :key="['room', room.id].join('.')" class="rental-type">
							<p class="text-left mb-0">{{ $transChoice('messages.room', 1) }} <strong>{{ room.name }}</strong></p>
							<small>{{ $localizedAttribute(room, 'description') }}</small>
							<ul v-if="room.sizes.length">
								<li	v-for="size in room.sizes"
									:key="['room', room.id, size.id].join('.')"
									class="d-flex justify-content-between">
									<div><strong>{{ size.name }}</strong> <span v-if="size.capacity">({{ [size.capacity, $transChoice('messages.place', size.capacity)].join(' ') }}) {{ parseFloat(size.pivot.price) > 0 ? ['+',size.pivot.price,'&euro;'].join('') : null }}</span></div>
									<div class="d-flex justify-content-between rental-selector quantity-selector">
										<Icon icon="dash-circle" @click="size.selected = (typeof size.selected === 'undefined' ? 0 : Math.max(size.selected - 1, 0))"></Icon>
										<strong>{{ size.selected || 0 }}</strong>
										<Icon icon="plus-circle" @click="size.selected = (typeof size.selected === 'undefined' ? 1 : (size.selected + 1))"></Icon>
									</div>
								</li>
							</ul>
							<div class="d-flex justify-content-between" v-else>
								<p class="text-left mb-0">{{ $transChoice('messages.room', 1) }} <strong>{{ room.name }}</strong> <span v-if="room.capacity">({{ [room.capacity, $transChoice('messages.place', room.capacity)].join(' ') }})</span></p>
								<div class="d-flex justify-content-between rental-selector quantity-selector">
									<Icon icon="dash-circle" @click="room.selected = (typeof room.selected === 'undefined' ? 0 : Math.max(room.selected - 1, 0))"></Icon>
									<strong>{{ room.selected || 0 }}</strong>
									<Icon icon="plus-circle" @click="room.selected = (typeof room.selected === 'undefined' ? 1 : (room.selected + 1))"></Icon>
								</div>
							</div>
						</div>
						<p v-if="participantsToPlace > 0" class="improoving-alert mb-1"><strong>Definisci la sistemazione per tutti i partecipanti</strong></p>
						<p v-else-if="participantsToPlace < 0" class="improoving-alert mb-1"><strong>Controlla che nelle stanze non risultino dei posti vuoti</strong></p>
					</template>
					<template v-else>
						<template v-if="showDateSelection">
							<p v-if="datesStillToSelect > 0" class="text-center mt-1 mb-1">Seleziona {{ datesStillToSelect }} {{ $transChoice('messages.day', datesStillToSelect).toLowerCase() }} per procedere. <span v-if="packet_price.consecutive">Se i giorni non sono consecutivi la tua richiesta verrà rifiutata</span></p>
							<ul class="list-group">
								<li v-for="date in selectableDates" :key="`selectable_dates.${date.date}`"
									@click="selectDate(date)"
									:class="`list-group-item selectable-date ${selected_dates.includes(date) ? 'list-group-item-success' : ''}`">
									<strong>{{ date.date }}:</strong> {{ $momentItemFormattedInterval(date.start_time, date.end_time, 'HH:mm') }}
								</li>
							</ul>
							<p v-if="datesStillToSelect > 0" class="text-center mt-1 mb-1">Seleziona {{ datesStillToSelect }} {{ $transChoice('messages.day', datesStillToSelect).toLowerCase() }} per procedere. <span v-if="packet_price.consecutive">Se i giorni non sono consecutivi la tua richiesta verrà rifiutata</span></p>
							<div class="btn btn-outline-dark mt-3 mb-3" @click="showDateSelection = false">{{ $trans('messages.back') }}</div>
						</template>
						<template v-else>
							<div v-if="showCalendar" class="w-100 text-center">
								<p v-if="hasVariations">Clicca sulla data di partenza che preferisci</p>
								<Calendar ref="calendar" :from-date="fromDate" :attributes="calendarAttributes" @dayclick="dayClicked"/>
							</div>

							<div v-if="allowDateSelection && typeof product.packet_prices !== 'undefined' && product.packet_prices.length"
								class="my-4">
								<p class="text-center">Quanti giorni parteciperai?</p>
								<div class="d-grid gap-2 px-5">
									<button v-for="packetPrice in product.packet_prices"
										:key="`product_price.${packetPrice.id}`"
										:class="`btn btn-${packet_price !== null && packet_price.id === packetPrice.id ? 'dark' : 'outline-dark'}`"
										type="button"
										@click="packet_price = packetPrice">
										{{ $packetPriceName(packetPrice) }} <span class="price">{{ $formattedPrice(packetPrice.price) }}</span>
									</button>
									<button :class="`btn btn-${packet_price === null ? 'dark' : 'outline-dark'}`"
										type="button"
										@click="packet_price = null">
										Tutto il corso
									</button>
								</div>
							</div>

						</template>
					</template>
				</template>
				<template v-else-if="step === 2">
					<h4 class="text-left">{{ $transChoice('messages.service', 2) }}</h4>
					<ul class="list-unstyled">
						<li v-for="service in availableServices" :key="['service', service.id].join('.')" class="service-list-item">
							<Checkbox @changeChecked="addServiceToAll(service)"
								@changeNotChecked="removeServiceFromAll(service)"
								:checked="typeof service.pivot !== 'undefined' && service.pivot.selection_type.includes('included')"
								:disabled="typeof service.pivot !== 'undefined' && service.pivot.selection_type.includes('not_removable')"><strong>{{ service.name }}</strong></Checkbox>
							<div class="ml-2 mr-2 mb-1">
								<p class="text-left mb-1">{{ $localizedAttribute(service, 'description') }}</p>
								<div v-if="typeof service.pivot === 'undefined' || !service.pivot.selection_type.includes('included') || showPriceOfIncludedServices"
									class="service-box">
									<strong>{{ $transChoice('messages.price', 1) }}:</strong>
									<span class="price">{{ $formattedPrice($servicePrice(service, participantsTotal, productTotalPrice)) }}</span>
								</div>
								<div v-if="service.marketingLink" class="d-flex justify-content-end"><a :href="service.marketingLink" target="_blank">Di cosa si tratta</a></div>
							</div>
						</li>
					</ul>
					<p class="improoving-alert mb-1"><strong>Nelle prossime schermate potrai scegliere di associare i servizi solo ai partecipanti che ti interessano</strong></p>
				</template>

				<PayerDetails v-else-if="step === 3" @goToPayment="nextStep"></PayerDetails>

				<form :id="$cartFormId" class="shopping-cart" @submit="this.$completeCheckout">
					<div v-if="step >= 4 && !isGiftCard" :class="step > 4 ? 'hidden' : ''">
						<SelectPupils :inputName="`pupils[packet][${product.id}]`"
							:pupilsNumber="participantsTotal"
							:selectionIsRequired="true"
							:servicesToAssociate="availableServices"
							:priceBaseForServices="productTotalPrice"
							:selectedRoomsDistribution="selectedRoomsDistribution"
							@selectPupil="registerPupil"
							@addService="addService"
							@removeService="removeService"></SelectPupils>
					</div>

					<SelectPaymentMethod v-if="step === 6"></SelectPaymentMethod>

					<OrderSummary v-if="![3].includes(step)"
						:product="product"
						:showExpanded="step === 5"
						@proceedToBooking="nextStep"></OrderSummary>
				</form>
			</div>

			<div class="d-grid mt-4 px-5">
				<button v-if="showDateSelection && step <3" class="btn btn-improoving" type="button" @click="nextStep" :disabled="datesStillToSelect > 0">
					{{ $trans('messages.addToCart') }}
				</button>
				<button v-else-if="mustSelectDates" class="btn btn-improoving" type="button" @click="showDateSelection = true">
					Seleziona i giorni del corso
				</button>
				<button v-else-if="![3].includes(step) && step < 5" class="btn btn-improoving" type="button" @click="nextStep" :disabled="proceedButtonIsDisabled">
					{{ $trans('marketing.nextStep') }}
				</button>
			</div>

		</template>
	</div>
</template>

<script>
import { Calendar } from 'v-calendar'
import Icon from "./_partials/Icon";
import {computed} from "vue";
import OrderSummary from "./_partials/OrderSummary";
import PayerDetails from "./PayerDetails";
import SelectPupils from "./SelectPupils";
import Checkbox from "./_partials/Checkbox";
import SelectPaymentMethod from "./SelectPaymentMethod";
import {isNumber} from "lodash";

export default {
	name: 'Product',
	props: {
		product: {
			type: Object,
			required: true
		},
		onlyDescription: {
			type: Boolean,
			default: false
		},
		fillColorWhenSelected: {
			type: String,
			default: 'green'
		},
		fillColorWhenNotSelected: {
			type: String,
			default: 'gray'
		},
	},
	inject: ['schoolId', 'category_id', 'allowDateSelection', 'services', 'section', 'showPriceOfIncludedServices'],
	provide(){
		return {
			isPacket: computed(() => this.isPacket),
			isEvent: computed(() => this.isEvent),
			isGiftCard: computed(() => this.isGiftCard),
			participants: computed(() => this.participants),
			participantsTotal: computed(() => this.participantsTotal),
			selectedRooms: computed( () => this.selectedRooms),
			roomSelectionIsComplete: computed( () => this.roomSelectionIsComplete),
			productTotalPrice: computed( () => this.productTotalPrice),
			selectedDates: computed(() => this.selected_dates),
			selectedServices: computed( () => this.selectedServices),
			selectedServicesTotal: computed( () => this.selectedServicesTotal),
			orderTotalPrice: computed( () => this.orderTotalPrice),
		}
	},
	components: {
		SelectPaymentMethod,
		Checkbox,
		OrderSummary,
		PayerDetails,
		SelectPupils,
		Icon,
		Calendar
	},
	data(){
		return {
			packets: {},
			products: {},
			packet_price: null,
			selected_dates: [],
			selected_pupils: {},
			clickedDate: null,
			showDateSelection: false,
			participants: {full_price: 0},
			step: 0
		}
	},
	mounted(){
		if(!this.onlyDescription && this.fromDate !== null){
			const calendar = this.$refs.calendar;
			calendar.move(this.fromDate);
		}
	},
	created(){
		if(!this.onlyDescription && !this.hasVariations)
			this.clickedDate = this.$momentItem(this.product.start_date);

		if(this.showRental)
			this.$requestGET(['school-database', this.schoolId, 'products'].join('/'))
			.then(r => {
				this.products = r.data
				document.dispatchEvent(new CustomEvent('loadedImproovingProducts-products'))
			})

		if(this.isPacket || this.isEvent){
			if(this.product !== null && typeof this.product.packet_prices !== 'undefined' && this.product.packet_prices !== null)
				this.participants = this.product.packet_prices.reduce((acc, price_variant) => {
					if(typeof price_variant.pivot !== 'undefined' && (price_variant.pivot.age_max !== null || price_variant.pivot.age_min !== null))
						acc[price_variant.id] = 0;
					return acc;
				}, {full_price: 0});
			else
				this.participants = {full_price: 0};

		}
	},
	computed: {
		isPacket(){
			return Object.prototype.hasOwnProperty.call(this.product, 'type') && this.product.type === 'packet';
		},
		isEvent(){
			return Object.prototype.hasOwnProperty.call(this.product, 'type') && this.product.type === 'event';
		},
		isGiftCard(){
			return Object.prototype.hasOwnProperty.call(this.product, 'price') && Object.prototype.hasOwnProperty.call(this.product,'value');
		},
		hasVariations(){
			return Object.prototype.hasOwnProperty.call(this.product, 'variations') && this.product.variations.length && this.product.type !== 'event';
		},
		showCalendar(){
			// OLD Method, can be deleted
			return false && !this.onlyDescription && this.product !== null && ['packet'].includes(this.product.type);
		},
		showRental(){
			return this.isEvent;
		},
		mustSelectDates(){
			return !this.onlyDescription && this.allowDateSelection && this.selectedVariation !== null && this.packet_price !== null && this.selected_dates.length === 0;
		},
		selectedVariation(){
			if(this.clickedDate === null && this.product !== null)
				return this.product;
			else if(this.$momentItem(this.clickedDate).isSame(this.$momentItem(this.product.start_date), 'day'))
				return this.product;
			else if(typeof this.product.variations !== 'undefined')
				return this.product.variations.find(el => this.$momentItem(this.clickedDate).isSame(this.$momentItem(el.start_date), 'day'));

			return null;
		},
		calendarAttributes(){
			if(!this.showCalendar)
				return [];

			let attributes = [],
				object = {
					highlight: {
						start: {
							color: this.$momentItem(this.clickedDate).isSame(this.$momentItem(this.product.start_date), 'day') ? this.fillColorWhenSelected : this.fillColorWhenNotSelected,
							fillMode: 'solid'
						},
						base: {
							color: this.$momentItem(this.clickedDate).isSame(this.$momentItem(this.product.start_date), 'day') ? this.fillColorWhenSelected : this.fillColorWhenNotSelected,
							fillMode: 'light'
						},
						end: {
							color: this.$momentItem(this.clickedDate).isSame(this.$momentItem(this.product.start_date), 'day') ? this.fillColorWhenSelected : this.fillColorWhenNotSelected,
							fillMode: 'outline'
						},
					},
					dates: { start: this.$momentItem(this.product.start_date).toDate(), end: this.$momentItem(this.product.end_date).toDate() }
				};

			attributes.push(object)
			if(this.hasVariations)
				this.product.variations.forEach(variation => {
					let object2 = {
						highlight: {
							start: {
								color: this.$momentItem(this.clickedDate).isSame(this.$momentItem(variation.start_date), 'day') ? this.fillColorWhenSelected : this.fillColorWhenNotSelected,
								fillMode: 'solid'
							},
							base: {
								color: this.$momentItem(this.clickedDate).isSame(this.$momentItem(variation.start_date), 'day') ? this.fillColorWhenSelected : this.fillColorWhenNotSelected,
								fillMode: 'light'
							},
							end: {
								color: this.$momentItem(this.clickedDate).isSame(this.$momentItem(variation.start_date), 'day') ? this.fillColorWhenSelected : this.fillColorWhenNotSelected,
								fillMode: 'outline'
							},
						},
						dates: { start: this.$momentItem(variation.start_date).toDate(), end: this.$momentItem(variation.end_date).toDate() }
					};

					attributes.push(object2)
				});

			return attributes;
		},
		fromDate(){
			return this.showCalendar && this.$momentItem(this.product.start_date).isAfter(this.$momentItem()) ? this.$momentItem(this.product.start_date).toDate() : null;
		},
		selectableDates(){
			return this.selectedVariation !== null && typeof this.selectedVariation.slots !== 'undefined' ?
				Object.values(this.selectedVariation.slots).sort((a, b) => {
					return this.$momentItem(a.start_time).isBefore(this.$momentItem(b.start_time)) ? -1 : 1;
				}).reduce((acc, slot) => {
					if(acc.length === 0 || !this.$momentItem(acc[acc.length-1].start_time).isSame(this.$momentItem(slot.start_time), 'day')){
						acc[acc.length] = {
							date: this.$momentItemFormatted(slot.start_time),
							start_time: slot.start_time,
							end_time: slot.end_time,
							slots: [slot]
						}
					}
					else{
						acc[acc.length-1].end_time = slot.end_time;
						acc[acc.length-1].slots.push(slot);
					}
					return acc;
				}, []) : [];
		},
		datesStillToSelect(){
			return this.selectableDates.length > 0 && this.packet_price !== null ? this.packet_price.days_number - this.selected_dates.length : 0;
		},
		availableRooms(){
			// A room must have a capacity specified to be valid
			return Object.values(this.products).reduce((acc, product) => {
				if(product.type === 'room' && (this.isEvent || this.isPacket)){
					if(product.sizes.length && typeof this.product.sizes !== "undefined" && this.product.sizes !== null){
						product.sizes = product.sizes.filter(size => {
							let size_found = this.product.sizes.find((el) => el.id === size.id);
							if(typeof size_found !== 'undefined' && size.capacity !== null && typeof size_found.pivot !== "undefined" && size_found.pivot.selection_type !== "not_allowed"){
								size.pivot = size_found.pivot;
								return size;
							}
						});
						acc.push(product);
					}
					else if(typeof this.product.products !== "undefined" && this.product.products !== null){
						let product_found = this.product.products.find((el) => el.id === product.id);
						if(typeof product_found !== 'undefined' && product.capacity !== null && typeof product_found.pivot !== "undefined" && product_found.pivot.selection_type !== "not_allowed")
							acc.push(product_found);

					}
				}

				return acc;
			}, []).sort((a, b) => {
				return a.name > b.name ? 1 : -1;
			})
		},
		selectedRooms(){
			return this.availableRooms.reduce((acc, room) => {
				if(typeof room.selected !== 'undefined' && room.selected > 0)
					acc.push(room);
				else if(room.sizes.length){
					room.sizes_selected = room.sizes.filter(size => {
						return typeof size.selected !== 'undefined' && size.selected > 0
					});
					if(room.sizes_selected.length)
						acc.push(room);
				}
				return acc;
			}, []);
		},
		selectedRoomsDistribution(){
			// Room name is computed here
			return this.selectedRooms.reduce((acc, room) => {
				if(typeof room.selected !== 'undefined' && room.selected > 0)
					for(let i = 1; i <= room.selected; i++)
						for(let j = 1; j <= room.capacity; j++)
							acc.push({
								id: room.id,
								type: 'product',
								name: [room.name, '-', i].join(' '),
								number: i
							});
				else if(typeof room.sizes_selected !== 'undefined' && room.sizes_selected.length)
					acc.concat(room.sizes_selected.reduce((acc2, size) => {
						if(typeof size.selected !== 'undefined' && size.selected > 0)
							for(let x = 1; x <= size.selected; x++)
								for(let z = 1; z <= size.capacity; z++)
									acc.push({
										id: size.id,
										type: 'size',
										name: [room.name, `(${size.name})`, '-', x].join(' '),
										number: x
									});

						return acc2;
					}, []));

				return acc;
			}, []);
		},
		selectedRoomsCapacity(){
			return this.selectedRooms.reduce((acc, room) => {
				if(typeof room.selected !== 'undefined' && room.selected > 0)
					acc += (room.selected * (room.capacity || 1));
				else if(room.sizes_selected.length)
					acc += room.sizes_selected.reduce((acc2, size) => {
						if(typeof size.selected !== 'undefined' && size.selected > 0)
							acc2 += (size.selected * (size.capacity || 1));

						return acc2;
					}, 0);

				return acc;
			}, 0);
		},
		selectedRoomsPrice(){
			return this.selectedRooms.reduce((acc, room) => {
				if(typeof room.selected !== 'undefined' && room.selected > 0){
					if(typeof room.pivot !== 'undefined')
						acc += ((room.capacity || 1) * room.selected * parseFloat(room.pivot.price));
				}
				else if(room.sizes_selected.length)
					acc += room.sizes_selected.reduce((acc2, size) => {
						if(typeof size.selected !== 'undefined' && size.selected > 0)
							if(typeof size.pivot !== 'undefined')
								acc2 += ((size.capacity || 1) * size.selected * parseFloat(size.pivot.price));
						return acc2;
					}, 0);

				return acc;
			}, 0);
		},
		participantsTotal(){
			return Object.values(this.participants).reduce((acc, participant_type) => {
				acc += participant_type;

				return acc;
			}, 0);
		},
		participantsToPlace(){
			return this.participantsTotal - this.selectedRoomsCapacity;
		},
		roomSelectionIsComplete(){
			return !this.isEvent || (this.availableRooms.length > 0 && this.participantsTotal > 0 && this.participantsToPlace === 0);
		},
		availableServices(){
			return Object.values(this.services).reduce((acc, service) => {
				if(this.isEvent && service['applies_to_events'] || this.isPacket && service['applies_to_packets']){
					if(typeof this.product.services !== "undefined" && this.product.services !== null){
						let service_found = this.product.services.find((el) => el.id === service.id);
						if(typeof service_found !== 'undefined' && typeof service_found.pivot !== "undefined" && service_found.pivot.selection_type !== "not_allowed")
							acc.push(service_found);
					}
					else if(service.sellable_online)
						acc.push(service);
				}

				return acc;
			}, [])
		},
		selectedServices(){
			return Object.values(this.availableServices).reduce((acc, service) => {
				if(typeof service.selected_pupils !== 'undefined')
					acc.push(service);

				return acc;
			}, [])
		},
		removedServices(){
			return Object.values(this.availableServices).reduce((acc, service) => {
				if(typeof service.pivot !== 'undefined' && service.pivot.selection_type.includes("included") && (typeof service.selected_pupils === 'undefined' || Object.values(service.selected_pupils).length < this.participantsTotal))
					acc.push(service);

				return acc;
			}, [])
		},
		selectedServicesTotal(){
			return Object.values(this.selectedServices).reduce((acc, service) => {
				// Services selected from staff do not count as additional to the price
				if(typeof service.pivot === 'undefined' || !service.pivot.selection_type.includes("included"))
					acc += this.$servicePrice(service, Object.values(service.selected_pupils).length, this.productTotalPrice)

				return acc;
			}, 0) - this.removedServicesTotal;
		},
		removedServicesTotal(){
			// This is the total amount discounted
			return Object.values(this.removedServices).reduce((acc, service) => {
				if(this.step >= 2)
					acc += this.$servicePrice(service, this.participantsTotal - (typeof service.selected_pupils === 'undefined' ? 0 : Object.values(service.selected_pupils).length), this.productTotalPrice)

				return acc;
			}, 0)
		},
		productTotalPrice(){
			return Object.keys(this.participants).reduce((acc, key) => {
				if(this.isPacket || this.isEvent){
					if(key === 'full_price')
						acc += parseFloat(this.packet_price !== null ? this.packet_price.price : this.product.price) * this.participants[key];
					else
						acc += parseFloat(this.product.packet_prices.find((el) => el.id == key).price) * this.participants[key];
				}
				else
					acc += parseFloat(this.product.price) * this.participants[key];

				return acc;
			}, 0) + this.selectedRoomsPrice;
		},
		orderTotalPrice(){
			return this.isEvent || this.isPacket ? this.productTotalPrice + this.selectedServicesTotal : null;
		},
		participantsAreSelected(){
			return Object.values(this.selected_pupils).length === this.participantsTotal;
		},
		proceedButtonIsDisabled(){
			return (this.step === 0 && this.participantsTotal === 0)
				|| (this.step === 1 && !this.roomSelectionIsComplete)
				|| (this.step === 4 && !this.participantsAreSelected);
		}
	},
	watch: {
		clickedDate(){
			this.selected_dates.splice(0, this.selected_dates.length);
		},
		step(newValue){
			if(newValue < 6)
				this.$emptyCart()
			else if(newValue === 6){
				let _this = this,
					product = this.product;

				if(this.isGiftCard){
					let sequence = Promise.resolve();

					for (let n = 1; n <= this.participantsTotal; n++)
						sequence = sequence.then(() => _this.$addToCart(product));

					sequence.then(() => _this.$checkoutCart());
				}
				else{
					if(this.selected_dates.length)
						product.slots_to_sell = this.selected_dates.reduce((acc, date) => {
							date.slots.forEach(slot => acc.push(slot.id));
							return acc;
						}, [])

					let promises = [
						_this.$addToCart(product, _this.participants)
					].concat(_this.selectedRooms.map(room => {
						return _this.$addToCart(room);
					}));

					Promise.all(promises).then(() => {
						_this.selectedServices.forEach(service => {
							service.apply_to = _this.isEvent ? 'events' : 'packets';
							service.price_total = _this.$servicePrice(service, Object.values(service.selected_pupils).length, _this.productTotalPrice);
							_this.$addToCart(service);
						});
					}).then(() => _this.$checkoutCart())
				}
			}
		},
		showDateSelection(newValue, oldValue){
			if(!newValue && oldValue)
				this.packet_price = null;
		}
	},
	methods: {
		isNumber,
		dayClicked(day){
			this.clickedDate = day.date
		},
		selectDate(date){
			if(this.datesStillToSelect > 0 && !this.selected_dates.includes(date))
				this.selected_dates.push(date)
			else if(this.selected_dates.includes(date))
				this.selected_dates.splice(this.selected_dates.indexOf(date), 1)
			else
				alert('Hai già selezionato il numero massimo di giorni')
		},
		previousStep(){
			if(this.step > 0)
				this.step--
			else
				this.$emit('resetSelection')

			if(this.stepShouldBeSkipped())
				this.previousStep()
		},
		nextStep(){
			this.step++;

			// Include the service to all participants
			if(this.step === 2)
				this.availableServices.forEach(service => {
					if(typeof service.pivot !== 'undefined' && service.pivot.selection_type.includes("included"))
						this.addServiceToAll(service);
				});

			if(this.stepShouldBeSkipped())
				this.nextStep()
		},
		stepShouldBeSkipped(){
			return (this.step === 1 && this.availableRooms.length === 0 && this.isEvent)
				|| (this.step === 1 && !this.allowDateSelection && this.isPacket)
				|| (this.step === 2 && this.availableServices.length === 0)
				|| (this.isGiftCard && [1, 4].includes(this.step));
		},
		addService($event){
			this.availableServices.forEach(service => {
				if(service.id === $event.service.id){
					if(typeof service.selected_pupils === 'undefined')
						service.selected_pupils = {};
					service.selected_pupils[$event.pupil_num] = true
				}
			});
		},
		addServiceToAll(service){
			// WARNING: this is 1-N and not 0-(N-1)
			let pupil_num = 1;
			do{
				this.addService({
					service: service,
					pupil_num: pupil_num
				});
				pupil_num++;
			}
			while(pupil_num <= this.participantsTotal)
		},
		removeService($event){
			this.availableServices.forEach(service => {
				if(service.id === $event.service.id && typeof service.selected_pupils !== 'undefined'){
					delete service.selected_pupils[$event.pupil_num];
					if(Object.values(service.selected_pupils).length === 0)
						delete service.selected_pupils;
				}
			});
		},
		removeServiceFromAll(service){
			// WARNING: this is 1-N and not 0-(N-1)
			let pupil_num = 1;
			do{
				this.removeService({
					service: service,
					pupil_num: pupil_num
				});
				pupil_num++;
			}
			while(pupil_num <= this.participantsTotal)
		},
		registerPupil($event){
			this.selected_pupils[$event.number] = $event.pupil_id;
		}
	}
}
</script>

