export default {
	install: (app) => {
		const invalid_class = 'is-invalid';

		app.config.globalProperties.$checkForm = (form_id) => {
			let isValid = true;
			return new Promise((resolve) => {
				document.querySelectorAll('#'+form_id+' .'+invalid_class).forEach(invalid => {invalid.classList.remove(invalid_class)});
				document.querySelectorAll('#'+form_id+' .required').forEach(group => {
					if(group.tagName.toLowerCase() === 'select' && (group.value === null || group.value.trim() === '')){
						group.classList.add(invalid_class);
						isValid = false;
					}
					else
						group.querySelectorAll('input').forEach(input => {
							if(input.value === null || input.value.trim() === ''){
								input.classList.add(invalid_class);
								isValid = false;
							}
						});
				});
				document.querySelectorAll('#'+form_id+' input.email').forEach(input => {
					if(!isValidEmail(input.value)){
						input.classList.add(invalid_class);
						isValid = false;
					}
				});
				resolve(isValid);
			});
		}

		app.config.globalProperties.$formDataObject = (form_id) => {
			return new FormData(document.querySelector('#'+form_id));
		}

		app.config.globalProperties.$validateAgainst = (input, validation_type) => {
			input.classList.remove(invalid_class);
			if(validation_type === 'email' && !isValidEmail(input.value))
				input.classList.add(invalid_class);
		}
	}
}

function isValidEmail(string){
	let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(string);
}