<style lang="scss">
#improoving-widget{
	$payer-background-color: rgba(238, 139, 82, 1);
	$payer-text-color: white;

	> header.payer-details-step {
		margin-top: -5px;
		padding-top: 5px;
		background-color: $payer-background-color;
		color: $payer-text-color;
	}

	> #main-content.payer-details-step .payer-details{
		margin: -15px;

		> .utility {
			padding: 5px;
			background-color: $payer-background-color;
			color: $payer-text-color;
		}
		> *:not(.utility) { padding: 15px; }

		.form-container{
			background-color: $payer-background-color;
			color: $payer-text-color;
			border-radius: 0 100% 55% 55%/0 0 15% 15%;
			padding-bottom: 100px;
		}

		h2 {
			text-align: center;
			margin-top: 35px;

			+ p { text-align: center; }
			+ p > strong{
				font-size: 1.3em;

				&:before{
					content: "\a";
					white-space: pre;
				}
			}
		}
	}
}
</style>

<template>
	<div class="payer-details">
		<div class="utility">
			<span @click="goBack"><Icon icon="arrow-left-circle mr-1"></Icon> {{ $trans('messages.back') }}</span>
		</div>
		<template v-if="$checkoutAsNewUser.value">
			<div class="form-container">
				<h2>{{ $trans('messages.createUser') }}</h2>
				<p>&copy; Improoving.me partner tecnologico di <strong>{{ schoolData.name }}</strong></p>
				<div class="input-group input-group-sm my-3">
					<input v-model="email" type="email" :placeholder="$transChoice('form_db.email', 1)" class="form-control" />
				</div>
				<div class="input-group input-group-sm my-3">
					<input v-model="password" :placeholder="$trans('form_db.password')" :type="show_password ? 'text' : 'password'" class="form-control" />
					<div class="input-group-append">
						<span class="input-group-text" @click="show_password = !show_password">{{ $trans(show_password ? 'hide' : 'show') }}</span>
					</div>
				</div>
				<div class="input-group input-group-sm my-3">
					<input v-model="phone" :placeholder="$transChoice('form_db.phone', 1)" class="form-control" />
				</div>
				<div class="input-group input-group-sm my-3">
					<input v-model="first_name" :placeholder="$transChoice('form_db.first_name', 1)" class="form-control" />
				</div>
				<div class="input-group input-group-sm my-3">
					<input v-model="last_name" :placeholder="$transChoice('form_db.last_name', 1)" class="form-control"  />
				</div>

				<div class="custom-control custom-checkbox">
					<input type="checkbox" v-model="consent" class="custom-control-input mr-1" id="customCheck1">
					<label class="custom-control-label" for="customCheck1">{{ $trans('options.data_collection_better') }}</label>
				</div>

				<div class="d-grid my-2">
					<button class="btn btn-primary" @click="proceedWithNewUser" :disabled="!inputIsComplete">
						{{ $trans('messages.createUser') }}
					</button>
				</div>
				<div v-if="$lastLoginMessage.value !== null" class="text-center">{{ $trans($lastLoginMessage.value) }}</div>
			</div>

			<p class="text-center">{{ $trans('messages.or') }}</p>

			<div class="d-grid my-2">
				<button class="btn btn-outline-dark" @click="$checkoutAsNewUser.value = false">
					{{ $trans('messages.login') }}
				</button>
			</div>
		</template>
		<template v-else-if="$loggedUser.value === null">
			<div class="form-container">
				<h2>{{ $trans('messages.login') }}</h2>
				<p>&copy; Improoving.me partner tecnologico di <strong>{{ schoolData.name }}</strong></p>
				<div class="input-group input-group-sm my-3">
					<input v-model="email" :placeholder="$transChoice('form_db.email', 1)" class="form-control" />
				</div>

				<div class="input-group input-group-sm my-3">
					<input v-model="password" :placeholder="$trans('form_db.password')" :type="show_password ? 'text' : 'password'" class="form-control" />
					<div class="input-group-append">
						<span class="input-group-text" @click="show_password = !show_password">{{ $trans(show_password ? 'hide' : 'show') }}</span>
					</div>
				</div>

				<!--
				<p class="text-center" @click="attemptResetPassword">{{ $trans('messages.forgotPassword')}}</p>
				-->

				<div class="d-grid my-2">
					<button class="btn btn-primary" @click="attemptLogin" :disabled="!showLoginButton">
						{{ $trans('messages.login') }}
					</button>
				</div>
				<div v-if="$lastLoginMessage.value !== null" class="text-center">{{ $trans($lastLoginMessage.value) }}</div>
			</div>

			<p class="text-center">{{ $trans('messages.or') }}</p>

			<div class="d-grid my-2">
				<button class="btn btn-outline-dark" @click="$checkoutAsNewUser.value = true">
					{{ $trans('messages.newCustomer') }}
				</button>
			</div>

		</template>
		<template v-else>
			<div class="form-container">
				<h3>{{ $trans('checkout.reviewPersonalDetails') }}</h3>
				<a href="#" @click="$logout">{{ $trans('messages.logout') }}</a>

				<div class="input-group input-group-sm my-3">
					<input v-model="email" type="email" :placeholder="$transChoice('form_db.email', 1)" class="form-control" />
				</div>
				<div class="input-group input-group-sm my-3">
					<input v-model="phone" :placeholder="$transChoice('form_db.phone', 1)" class="form-control" />
				</div>
				<div class="input-group input-group-sm my-3">
					<input v-model="first_name" :placeholder="$transChoice('form_db.first_name', 1)" class="form-control" />
				</div>
				<div class="input-group input-group-sm my-3">
					<input v-model="last_name" :placeholder="$transChoice('form_db.last_name', 1)" class="form-control"  />
				</div>

				<div class="custom-control custom-checkbox">
					<input type="checkbox" v-model="consent" class="custom-control-input mr-1" id="customCheck2">
					<label class="custom-control-label" for="customCheck2">{{ $trans('options.data_collection_better') }}</label>
				</div>

				<div class="d-grid my-2">
					<button class="btn btn-primary" @click="goToPayment" :disabled="!inputHasEverythingButPassword">
						{{ $trans('checkout.proceedToPayment') }}
					</button>
				</div>
			</div>
		</template>
	</div>
</template>

<script>

import {computed} from "vue";
import Icon from "./_partials/Icon";

export default {
	name: 'PayerDetails',
	components: {Icon},
	props: {
		hidden: {
			type: Boolean,
			default: false
		}
	},
	inject: ['section', 'schoolData'],
	data(){
		return {
			email: null,
			password: null,
			phone: null,
			first_name: null,
			last_name: null,
			show_password: false,
			consent: false
		}
	},
	created(){
		let _this = this;
		document.addEventListener('improovingUserLoaded', () => {
			this.loadLoggedUser()
		});
		document.addEventListener('improovingUserLogout', () => {
			['email', 'phone', 'first_name', 'last_name', 'password'].forEach(key => _this[key] = null)
		});

		if(this.$loggedUser.value !== null)
			this.loadLoggedUser();
	},
	provide(){
		return {
			user_email: computed(() => this.email),
			user_password: computed(() => this.password),
			user_firstName: computed(() => this.first_name),
			user_lastName: computed(() => this.last_name)
		}
	},
	computed: {
		inputHasEverythingButPassword(){
			return ![null, ""].includes(this.email) && ![null, ""].includes(this.first_name) && ![null, ""].includes(this.last_name) && this.consent
		},
		inputIsComplete(){
			return this.inputHasEverythingButPassword && ![null, ""].includes(this.password)
		},
		showLoginButton(){
			return ![null, ""].includes(this.email) && ![null, ""].includes(this.password)
		}
	},
	methods: {
		attemptLogin(){
			let _this = this;
			_this.$login(_this.email, _this.password);
		},
		proceedWithNewUser(){
			this.$createUser(this.email, this.password, this.first_name, this.last_name, this.phone)
			.then(() => {
				this.loadLoggedUser();
				this.goToPayment();
			})
		},
		proceedWithoutCreating(){
			this.$goToCart.value = true;
			this.$checkoutAsNewUserInfo.value = {
				first_name: this.first_name,
				last_name: this.last_name,
				email: this.email,
				password: this.password,
				password_confirmation: this.password_confirmation,
				phone: this.phone
			};
			this.$loggedUserInfoIsUpdated.value = true;
		},
		loadLoggedUser(){
			let _this = this;
			['email', 'phone', 'first_name', 'last_name'].forEach(key => _this[key] = _this.$personAttribute(_this.$loggedUser.value, key));
		},
		goToPayment(){
			if(['flowPacket', 'flowEvent', 'flowGiftCard'].includes(this.section))
				this.$emit('goToPayment');
			else{
				console.warn("calling checkout directly from here");
				this.$checkoutCart().then(() => {
					this.$goToCart.value = true;
					this.$loggedUserInfoIsUpdated.value = true;
				});
			}
		},
		goBack(){
			this.$goToPayerDetails.value = false;
		}
	}
}
</script>

<style>
</style>