<template>
	<div v-if="StripeInsideImprooving !== null">
		<div class="card">
			<div id="new-card-details" class="card-body">
				<label for="card-element">
					Credit or debit card
				</label>
				<div id="card-element">
					<!-- a Stripe Element will be inserted here. -->
				</div>

				<!-- Used to display form errors -->
				<div id="card-errors" role="alert"></div>
			</div>
		</div>

		<div class="d-grid my-2 px-5">
			<button class="btn btn-primary" @click="attemptPayment" :disabled="waitingResponse">
				{{ $trans('checkout.completePurchase') }} ({{ $amountToPayDuringCheckoutFormatted.value }})
			</button>
		</div>
	</div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js'

export default {
	props:{
		cardStyle: {
			type: Object,
			default(){
				return {
					base: {
						color: '#32325d',
						lineHeight: '18px',
						fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
						fontSmoothing: 'antialiased',
						fontSize: '16px',
						'::placeholder': {
							color: '#aab7c4'
						}
					},
					invalid: {
						color: '#fa755a',
						iconColor: '#fa755a'
					}
				}
			}
		}
	},
	inject: ['schoolId', 'schoolData'],
	data() {
		return {
			StripeInsideImprooving: null,
			elements: null,
			card: null,
			waitingResponse: false,
		}
	},
	watch:{
		schoolData(){
			this.checkSchoolStripe();
		}
	},
	created(){
		this.checkSchoolStripe();
	},
	computed:{
		stripePublishableKey(){
			return this.$isTestEnvironment ? 'pk_test_DUjgHxbTe0ubca7eWngAQuxU' : 'pk_live_kBAolgr5lVkOj21V5OI7M4Fq';
		},
		schoolStripeUserId(){
			return this.schoolData !== null
				&& typeof this.schoolData.stripe !== 'undefined' && this.schoolData.stripe !== null
				&& this.schoolData.stripe.stripe_user_id !== 'undefined' && this.schoolData.stripe.stripe_user_id !== null ?
				this.schoolData.stripe.stripe_user_id : null;
		}
	},
	methods:{
		checkSchoolStripe(){
			if(this.StripeInsideImprooving === null && this.schoolStripeUserId !== null)
				loadStripe(this.stripePublishableKey, { stripeAccount: this.schoolStripeUserId }).then(r => {
					this.StripeInsideImprooving = r;

					this.elements = this.StripeInsideImprooving.elements();
					this.card = this.elements.create('card', {style: this.style});

					this.$nextTick(() => {
						this.card.mount('#card-element');
						this.card.addEventListener('change', function(event) {
							let displayError = document.getElementById('card-errors');
							if (event.error) {
								displayError.textContent = event.error.message;
							} else {
								displayError.textContent = '';
							}
						});

					})
				})
		},
		attemptPayment(){
			let _this = this;

			return new Promise((resolve, reject) => {
				_this.waitingResponse = true;

				_this.StripeInsideImprooving.createPaymentMethod({type: 'card', card: this.card})
				.then(result => {
					if(result.error){
						_this.waitingResponse = false;

						let errorElement = document.getElementById('card-errors');
						errorElement.textContent = result.error.message;
						reject(result.error);
					}
					else{
						resolve(_this.stripePaymentMethodHandler(result.paymentMethod.id));
					}
				})

			})
			.catch(() => {_this.waitingResponse = false;});
		},
		stripePaymentMethodHandler(paymentMethodId){
			let data = {
				amount: this.$amountToPayDuringCheckout.value,
				payment_method_id: paymentMethodId,
				capture_method: 'manual',
				school_id: this.schoolId
			}

			return this.$requestPOST('confirm-payment', data)
			.then(r => {
				this.stripePaymentMethodServerResponseHandler(r.data, data)
			})
		},
		stripePaymentMethodServerResponseHandler(response, data = null){
			if(response.requires_action){
				this.StripeInsideImprooving.handleCardAction(response.payment_intent_client_secret)
				.then(result => {
					if(result.error){
						let displayError = document.getElementById('card-errors');
						displayError.textContent = result.error.message;
						console.warn(result.error)
					}
					else{
						let input = {
							payment_intent_id: result.paymentIntent.id,
							school_id: this.schoolId
						}

						this.$requestPOST('confirm-payment', input)
						.then(confirmResult => {
							this.stripePaymentMethodServerResponseHandler(confirmResult.data, data);
						});
					}
				});
			}
			else if(response.success || response.requires_capture){
				this.$paymentIntentId.value = response.payment_intent_id;
				this.$completeCheckout();
			}
			else{
				this.waitingResponse = false;
				console.log(response);
			}
		}
	}
}
</script>