<style>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");

/*Refer to https://icons.getbootstrap.com/ for a list of all available icons */

</style>

<template>
	<i :class="`bi-${icon}`"></i>
</template>

<script>
export default{
	name: 'Icon',
	props: {
		icon: {
			/* This field accepts extra classes! */
			type: String,
			required: true
		},
	},
}
</script>