import moment from "moment";

export default {
	install: (app) => {
		let locale_fallback = 'DD/MM/YYYY'; // moment.localeData().longDateFormat('L')
		app.config.globalProperties.$momentItem = (string = null) => {
			return string === null ? moment() : momentItemFromString(string);
		}

		app.config.globalProperties.$momentItemFormatted = (string = null, format = null) => {
			return app.config.globalProperties.$momentItem(string).format(format || locale_fallback);
		}

		app.config.globalProperties.$momentItemFormattedInterval = (string_start = null, string_end = null, format = null) => {
			return app.config.globalProperties.$momentItemFormatted(string_start, format) + ' - ' + app.config.globalProperties.$momentItemFormatted(string_end, format);
		}

		app.config.globalProperties.$slotsAreConsecutive = (earlier_slot, later_slot) => {
			return earlier_slot.school_id === later_slot.school_id
				&& app.config.globalProperties.$momentItem(earlier_slot.end_time).isSame(app.config.globalProperties.$momentItem(later_slot.start_time));
		}

		app.config.globalProperties.$differenceInMinutes = (start_time, end_time) => {
			return app.config.globalProperties.$momentItem(end_time).diff(app.config.globalProperties.$momentItem(start_time), 'minutes');
		}
	}
}

function momentFormat(date_to_parse){
	if(moment(date_to_parse, moment.ISO_8601, true).isValid())
		return moment.ISO_8601;
	else if(moment(date_to_parse, 'YYYY-MM-DD', true).isValid())
		return 'YYYY-MM-DD';
	else if(moment(date_to_parse, 'DD/MM/YYYY', true).isValid())
		return 'DD/MM/YYYY';
	else if(moment(date_to_parse, 'DD-MM-YYYY', true).isValid())
		return 'DD-MM-YYYY';
	else if(moment(date_to_parse, 'YYYY-MM-DD HH:mm a', true).isValid())
		return 'YYYY-MM-DD HH:mm a';
	else if(moment(date_to_parse, 'DD/MM/YYYY HH:mm a', true).isValid())
		return 'DD/MM/YYYY HH:mm a';
	else if(moment(date_to_parse, 'DD-MM-YYYY HH:mm a', true).isValid())
		return 'DD-MM-YYYY HH:mm a';
	else
		return null;
}
function momentItemFromString(date){
	let response;
	try{
		date = date.replace(/\b(\d{1})\b/g, '0$1');
		response = moment(date, momentFormat(date));
	}
	catch(err){
		console.log('[Handled] Date creation for moment has returned an error', date);
		console.warn(err);
		response = moment(date);
	}
	return response;
}