export default {
	install: (app) => {
		app.config.globalProperties.$saveInStorage = async (key, value) => {
			return saveInLocalStorage(key, value);
		}
		app.config.globalProperties.$getFromStorage = async (key) => {
			return getFromLocalStorage(key);
		}
		app.config.globalProperties.$removeFromStorage = async (key) => {
			return removeFromLocalStorage(key);
		}
	}
}

async function saveInLocalStorage(key, value){
	return localStorage.setItem(key, value)
}
async function getFromLocalStorage(key){
	return localStorage.getItem(key)
}
async function removeFromLocalStorage(key){
	return localStorage.removeItem(key);
}