<style lang="scss">
#improoving-widget{
	.product-lesson{
		.category-icon{
			margin-top: -20px;
			width: 30%;
		}
	}
}

</style>

<template>
	<div class="product product-lesson">
		<slot></slot>

		<div class="row">
			<div class="col col-md-4 col-lg-3 text-center">
				<img	:src="hasTeacher ? teacher.person.picture.url : logoUrl"
						:alt="hasTeacher ? teacher.person.picture.alt : schoolData.name"
						:class="`img-fluid ${hasTeacher ? 'teacher-image rounded-circle' : 'lessons-no-teacher rounded'}`" />
				<div v-if="category.icon !== null" class="d-flex justify-content-end">
					<img	:src="$baseUrl + category.icon"
							:alt="category.specialization"
							class="rounded-circle category-icon" />
				</div>
				<p class="text-center mimic-link text-danger" @click="removeFromCart">{{ $trans('messages.remove') }}</p>
			</div>
			<div class="col col-md-8 col-lg-9 watermark-container">
				<div class="table-responsive">
					<table class="lesson-purchased-table purchase-table table table-sm border-0">
						<tr>
							<td>{{ $transChoice('messages.date', 1) }}</td>
							<td>{{ $momentItemFormatted(startTime) }}</td>
						</tr>
						<tr>
							<td>{{ $transChoice('messages.timeInterval', 1) }}</td>
							<td>{{ $momentItemFormattedInterval(startTime, endTime, 'HH:mm') }}</td>
						</tr>
						<tr>
							<td>{{ $transChoice('messages.teacher', 1) }}</td>
							<td>{{ hasTeacher ? $personIdentifier(teacher.person) : $trans('messages.noTeacherSelected') }}</td>
						</tr>
						<tr>
							<td>{{ $transChoice('messages.category', 1) }}</td>
							<td>{{ $trans('translations.'+category.specialization) }}</td>
						</tr>
						<tr>
							<td>{{ $transChoice('messages.pupil', 2) }}</td>
							<td>{{ pupilsNumber }}</td>
						</tr>
						<tr v-if="false && typeof rendezvous !== 'undefined'">
							<td>{{ $transChoice('messages.rendezvous', 2) }}</td>
							<td>Fornire la scelta</td>
						</tr>
						<tr>
							<td>{{ $transChoice('messages.price', 1) }}</td>
							<td>
								<span v-if="lesson.price != lesson.price_original && parseFloat(lesson.price) < parseFloat(lesson.price_original)" class="gutter-right-small"><s>{{ $formattedPrice(lesson.price_original) }}&euro;</s> <span class="applied-discount btn btn-xs btn-success"></span></span>
								<strong>{{ $formattedPrice(lesson.price) }}</strong>
							</td>
						</tr>
						<tr v-if="typeof lesson.services !== 'undefined' && Object.values(lesson.services).length">
							<td>{{ $transChoice('messages.service', Object.values(lesson.services).length) }}</td>
							<td>
								<ul class="list-unstyled mb-0">
									<li v-for="service in Object.values(lesson.services)" :key="['services', lesson.id, service.id].join('.')">
										<icon icon="plus" iconType="ft"></icon> {{ $serviceName(service) }}{{ $formattedPrice(service.amount)+'€' }}
									</li>
								</ul>
							</td>
						</tr>
					</table>
				</div>

				<p v-if="typeof lesson.error !== 'undefined' && lesson.error != ''" class="watermark">{{ lesson.error }}</p>
				<p v-else-if="typeof lesson.available !== 'undefined' && !lesson.available" class="watermark">{{ $trans('messages.notAvailable') }}</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Lesson',
	props: {
		lesson: {
			type: Object,
			required: true
		},
		onlyDescription: {
			type: Boolean,
			default: false
		}
	},
	inject: ['schoolId', 'teachers', 'schoolData', 'logoUrl'],
	data(){
		return {
		}
	},
	mounted(){
	},
	created(){
	},
	computed: {
		hasTeacher(){
			return typeof this.lesson.req_asso_id !== 'undefined' && this.lesson.req_asso_id !== null;
		},
		hasTeachersAvailable(){
			return typeof this.lesson.slot_id !== 'undefined' && typeof this.lesson.teachers_available !== 'undefined' && this.lesson.teachers_available.length;
		},
		teacher(){
			let _this = this;
			return this.hasTeacher ?
				Object.values(this.teachers).find(teacher => {
					return teacher.id === parseInt(_this.lesson.req_asso_id);
				}) : null;
		},
		startTime(){
			return this.lesson.start_time || this.lesson.slot.start_time;
		},
		endTime(){
			return this.lesson.end_time || this.lesson.slot.end_time;
		},
		pupilsNumber(){
			return this.lesson.pupils_number || this.lesson.pupils_num || (this.lesson.pupils instanceof Array ? 1 : Math.max(1, this.lesson.pupils))
		},
		pupilsKey(){
			return typeof this.lesson.ta_id !== 'undefined' ? 'pupils' : 'pupils_num';
		},
		rendezvous(){
			return typeof this.lesson.rendezvous !== 'undefined' && this.lesson.rendezvous !== null ?
				this.lesson.rendezvous : Object.values(this.schoolData.rendezvous).find(rdv => {
					return typeof this.lesson.rendezvous_id !== 'undefined' && this.lesson.rendezvous_id !== null ?
						rdv.id == this.lesson.rendezvous_id : rdv.default == 1;
				});
		},
		category(){
			let _this = this;
			return this.schoolData.categories.find(category => {
				return category.id === _this.lesson.category.id;
			});
		}
	},
	watch: {
	},
	methods: {
		removeFromCart(){
			let objects = [];
			if(typeof this.lesson.ta_id !== 'undefined'){
				let ta_ids = this.lesson.ta_id.split(',');

				this.$cart.value.lessons.forEach(availability => {
					if(ta_ids.includes(availability.id.toString()))
						objects.push(availability);
				});
			}
			else if(typeof this.lesson.slot_id !== 'undefined'){
				let slot_ids = this.lesson.slot_id.split(',');

				this.$cart.value.lessons_no_teacher.forEach(slot => {
					if(slot_ids.includes(slot.id.toString()))
						objects.push(slot);
				});
			}
			else
				throw Error('Product not recognized');

			this.$removeFromCart(objects);
		}
	}
}
</script>

