<style>
#improoving-widget label > input[type="radio"]{
	margin-right: 5px;
}
</style>

<template>
	<div>
		<div v-for="value in values"
			:key="[name ,value.key, 'radio'].join('.')"
			:class="divClasses">
			<label :class="labelClass">
				<input v-if="disabled"
					type="radio"
					autocomplete="off"
					:id="id"
					:name="name"
					:value="value.key"
					:class="radioClass"
					:checked="value.key === checkedModel ? 'checked' : null"
					v-model="checkedModel" disabled />
				<input type="radio"
					autocomplete="off"
					:id="id"
					:name="name"
					:value="value.key"
					:class="radioClass"
					:checked="value.key === checkedModel ? 'checked' : null"
					v-model="checkedModel" v-else/>
				{{ value.value }}
			</label>
		</div>
	</div>
</template>
<script>
export default {
	name: 'Radio',
	props: {
		id: {
			type: String,
			default: null
		},
		values: {
			type: Array,
			required: true,
			validator(value){
				return typeof value.find(el => {
					return typeof el.key === 'undefined'
						|| typeof el.value === 'undefined'
						|| !(el.value instanceof String);
				}) === 'undefined';
			}
		},
		name: {
			type: String,
			required: true
		},
		radioClasses: {
			type: String,
			default: null
		},
		classes: {
			type: String,
			default: null
		},
		classesLabel: {
			type: String,
			default: null
		},
		disabled: {
			type: Boolean,
			default: false
		},
	},
	data(){
		return {
			checkedModel: Object.values(this.values)[0].key
		}
	},
	computed: {
		radioClass(){
			let classes = [
				'form-check-input pointer',
				this.radioClasses,
				'position-static'
			];
			return classes.join(' ');
		},
		divClasses(){
			let classes = ['form-check radio', this.classes];
			return classes.join(' ');
		},
		labelClass(){
			let classes = ['form-check-label pointer d-flex', this.classesLabel];
			return classes.join(' ');
		}
	},
	watch: {
		checkedModel(newValue){
			let _this = this;
			_this.$nextTick(function(){
				_this.$emit('changeRadio', newValue);
			});
		}
	},
	methods: {}
}
</script>