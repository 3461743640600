import { ref, computed } from 'vue'

let empty_cart = {
	worked: [],
	lessons: [],
	lessons_services: [],
	lessons_no_teacher: [],
	lessons_no_teacher_services: [],
	packets: [],
	packets_services: [],
	events: [],
	events_services: [],
	moments: [],
	moments_services: [],
	giftcards: [],
	giftcards_services: [],
	rentals: [],
	rentals_services: []
}

export default {
	install: (app, options) => {
		const cart_key = 'improoving-shopping_cart'
		const last_cart_save = 'improoving-last_cart_save'

		app.config.globalProperties.$cart = ref(JSON.parse(JSON.stringify(empty_cart)))
		app.config.globalProperties.$cartPriceFromServer = ref(0);
		app.config.globalProperties.$cartFormId = 'improoving-shopping-cart';
		app.config.globalProperties.$paymentIntentId = ref(null);
		app.config.globalProperties.$lastCartMessage = ref({});
		app.config.globalProperties.$orderID = ref(null);

		if(options.depositPercentage && (isNaN(options.depositPercentage) || options.depositPercentage > 1 || options.depositPercentage <= 0))
			console.error('depositPercentage must be a number between 0 and 1', {options: options});
		if(options.depositAmountMinimum && (isNaN(options.depositAmountMinimum) || options.depositAmountMinimum <= 0))
			console.error('depositPercentage must be a number greater than 0', {options: options});

		app.config.globalProperties.$depositPercentage = options.depositPercentage || 1;
		app.config.globalProperties.$depositAmountMinimum = options.depositAmountMinimum || null;
		app.config.globalProperties.$allowDepositPayment = computed(() => {
			return app.config.globalProperties.$depositAmount < app.config.globalProperties.$cartTotal
				&& (app.config.globalProperties.$depositAmountMinimum === null || app.config.globalProperties.$depositAmount > app.config.globalProperties.$depositAmountMinimum);
		});

		app.config.globalProperties.$paymentTypes = computed(() => {
			let response_array = [{key: 'total', value: 'Unica Soluzione'}];
			if(options.allowAdvancePayment)
				response_array.push({key: 'deposit', value: 'Acconto + Saldo'});
			return response_array;
		});
		app.config.globalProperties.$advancePaymentIsAllowed = computed(() => {
			return Object.keys(app.config.globalProperties.$paymentTypes).includes("deposit");
		});
		app.config.globalProperties.$paymentType = ref(app.config.globalProperties.$paymentTypes.value[0].key);
		app.config.globalProperties.$paymentMethods = computed(() => {
			return [{key: 'credit_card', value: app.config.globalProperties.$transChoice('messages.creditCard', 1)}, /* {key: 'bank_account', value: app.config.globalProperties.$transChoice('messages.bankAccount', 1)} */];
		});
		app.config.globalProperties.$paymentMethod = ref(app.config.globalProperties.$paymentMethods.value[0].key);

		app.config.globalProperties.$getFromStorage(cart_key).then(r => {
			console.log('Improoving Cart found', r)

			app.config.globalProperties.$cart.value = r === null ? JSON.parse(JSON.stringify(empty_cart)) : JSON.parse(r)
		});

		// Cart computed values
		app.config.globalProperties.$cartCount = computed(() => {
			let count = 0;

			Object.keys(app.config.globalProperties.$cart.value).forEach(category => {
				if(!category.includes('service'))
					count += app.config.globalProperties.$cart.value[category].reduce((acc, object) => {
						return acc + (typeof object.selected !== 'undefined' ? parseFloat(object.selected) : 1);
					}, 0);
			});

			return count;
		})

		app.config.globalProperties.$cartLessons = computed(() => {
			return mapLessons(app.config.globalProperties.$cart.value.lessons, app);
		})

		app.config.globalProperties.$cartLessonsNoteacher = computed(() => {
			return mapLessonsNoTeacher(app.config.globalProperties.$cart.value.lessons_no_teacher, app);
		})

		app.config.globalProperties.$cartTotal = computed(() => {
			if(app.config.globalProperties.$cartPriceFromServer.value !== 0)
				return app.config.globalProperties.$cartPriceFromServer.value;

			let total = 0;

			Object.keys(app.config.globalProperties.$cart.value).forEach(category => {
				app.config.globalProperties.$cart.value[category].forEach(object => {
					if(object.price_total)
						total += object.price_total;
					else if(object.price)
						total += (typeof object.selected !== 'undefined' ? (object.selected * parseFloat(object.price)) : parseFloat(object.price));
					else
						console.warn('The following object does not have a price', object);
				})
			});

			return total;
		})
		app.config.globalProperties.$cartTotalFormatted = computed(() => {
			// https://stackoverflow.com/questions/149055/how-to-format-numbers-as-currency-strings
			let formatter = new Intl.NumberFormat(app.config.globalProperties.$language.value, {
				style: 'currency',
				currency: 'EUR',
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			});

			return formatter.format(app.config.globalProperties.$cartTotal.value);
		})
		app.config.globalProperties.$depositAmount = computed(() => {
			return app.config.globalProperties.$cartTotal.value * app.config.globalProperties.$depositPercentage;
		})
		app.config.globalProperties.$depositAmountFormatted = computed(() => {
			// https://stackoverflow.com/questions/149055/how-to-format-numbers-as-currency-strings
			let formatter = new Intl.NumberFormat(app.config.globalProperties.$language.value, {
				style: 'currency',
				currency: 'EUR',
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			});
			return formatter.format(app.config.globalProperties.$depositAmount.value);
		})
		app.config.globalProperties.$amountToPayDuringCheckout = computed(() => {
			return app.config.globalProperties.$paymentType.value === 'deposit' ?
				app.config.globalProperties.$depositAmount.value : app.config.globalProperties.$cartTotal.value;
		});
		app.config.globalProperties.$amountToPayDuringCheckoutFormatted = computed(() => {
			// https://stackoverflow.com/questions/149055/how-to-format-numbers-as-currency-strings
			let formatter = new Intl.NumberFormat(app.config.globalProperties.$language.value, {
				style: 'currency',
				currency: 'EUR',
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			});

			return formatter.format(app.config.globalProperties.$amountToPayDuringCheckout.value);
		});

		// Cart Management Methods
		app.config.globalProperties.$formattedPrice = (price) => {
			// https://stackoverflow.com/questions/149055/how-to-format-numbers-as-currency-strings
			let formatter = new Intl.NumberFormat(app.config.globalProperties.$language.value, {
				style: 'currency',
				currency: 'EUR',
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			});

			return formatter.format(price);
		}

		app.config.globalProperties.$servicePrice = (service, people, amount = null) => {
			if(typeof service.pivot !== 'undefined')
				return parseFloat(service.pivot.price || 0) * people;

			if(service.price_type === 'value_per_person')
				return parseFloat(service.rates[Math.min(people, 7)].price);

			return service.price_type === 'value' ? (parseFloat(service.price) * people) : (parseFloat(service.price)/100 * amount);
		}


		// WARNING: participants is a key/value object
		app.config.globalProperties.$addToCart = (object, participants = null) => {
			app.config.globalProperties.$goToPayment.value = false

			return new Promise((resolve, reject) => {
				if(participants === null)
					app.config.globalProperties.$checkIfInCart(object).then(response => {
						if(typeof object.pupils !== 'undefined')
							delete object.pupils;

						if(response.can_be_pushed){
							if(response.can_be_added)
								object.selected = 1;
							app.config.globalProperties.$cart.value[response.category].push(object);
						}
						else if(response.can_be_added)
							app.config.globalProperties.$cart.value[response.category][response.index].selected += 1;
						else
							return reject('Item already in cart');

						app.config.globalProperties.$saveCart();
						resolve('Item added to cart');
					});
				else{
					// https://stackoverflow.com/questions/40328932/javascript-es6-promise-for-loop
					let total_pupils = Object.values(participants).reduce((acc, el) => {
						acc += el;
						return acc;
					}, 0);
					(function loop(i) {
						//TODO: change price according to age!!!
						if (i > total_pupils){
							console.log('Item added to cart according participants specified', {object: object, participants: participants});
							resolve('Item added to cart according participants specified');
							return; // all done
						}
						else
							app.config.globalProperties.$addToCart(object).then(() => {
								console.log('Added to cart', i);
								loop(i+1);
							});
					})(1);

				}
			});
		}

		app.config.globalProperties.$removeFromCart = (object) => {
			return new Promise((resolve, reject) => {
				if(object instanceof Array){
					// Remove multiple objects at once
					let promises = [];
					object.forEach((item) => {
						promises.push(app.config.globalProperties.$checkIfInCart(item));
					})

					Promise.all(promises).then(response => {
						let categories = {};
						response.forEach((item) => {
							if(typeof categories[item.category] === 'undefined' && item.in_cart)
								categories[item.category] = [];

							if(item.in_cart)
								categories[item.category].push(item.index);
						});
						Object.keys(categories).forEach(key => {
							// Start from last, otherwise the indexes will update
							categories[key].sort(function(a, b){return b-a});
							categories[key].forEach((index) => {
								app.config.globalProperties.$cart.value[key].splice(index, 1)
							})
						});

						app.config.globalProperties.$saveCart();
					});
				}
				else{
					// Remove one object
					app.config.globalProperties.$checkIfInCart(object).then(response => {
						if(response.in_cart){
							app.config.globalProperties.$cart.value[response.category].splice(response.index, 1);
							app.config.globalProperties.$saveCart();
							resolve('Item removed from cart');
						}
						else
							reject('Item not in cart');
					});
				}
			}).then(() => {
				app.config.globalProperties.$checkCartWithServer();
			})
		}

		app.config.globalProperties.$updateObject = (object) => {
			return new Promise((resolve, reject) => {
				app.config.globalProperties.$checkIfInCart(object).then(response => {
					if(response.in_cart){
						app.config.globalProperties.$cart.value[response.category][response.index] = object;
						app.config.globalProperties.$saveCart();
						resolve('Item updated');
					}
					else
						reject('Item not in cart');
				}).then(() => {
					app.config.globalProperties.$checkCartWithServer();
				})
			});
		}

		app.config.globalProperties.$emptyCart = () => {
			app.config.globalProperties.$goToPayment.value = false

			app.config.globalProperties.$cart.value = JSON.parse(JSON.stringify(empty_cart))
			app.config.globalProperties.$saveCart();
		}

		// Checkout Process

		app.config.globalProperties.$checkCartWithServer = () => {
			// Use this when you just need to check with the server
			return new Promise((resolve) => {
				app.config.globalProperties.$requestPOST('check-cart', app.config.globalProperties.$cart.value).then(r => {
					if(typeof r.data.cart !== 'undefined'){
						app.config.globalProperties.$cart.value = r.data.cart;
						app.config.globalProperties.$saveCart();
						resolve(r.data)
					}
					else
						reportError(r)
				})
			})
		}

		app.config.globalProperties.$checkoutCart = () => {
			// Use this to check with server AND proceed to checkout
			return new Promise((resolve, reject) => {
				app.config.globalProperties.$requestPOST('checkout', app.config.globalProperties.$cart.value).then(r => {
					app.config.globalProperties.$lastCartMessage.value = r.data;
					if(typeof r.data.cart !== 'undefined'){
						// Price should always be reported back, in case of variations being applied
						if(typeof r.data.price !== 'undefined'){
							console.warn("Received price from server", r.data.price);
							app.config.globalProperties.$cartPriceFromServer.value = r.data.price;
						}

						app.config.globalProperties.$cart.value = r.data.cart;
						app.config.globalProperties.$saveCart();
						if(r.data.proceed_to_payment){
							app.config.globalProperties.$goToPayment.value = true
							resolve(r.data)
						}
						else
							reject('Not allowed to proceed')
					}
					else
						reportError(r)
				})
			})
		}

		app.config.globalProperties.$completeCheckout = () => {
			return new Promise((resolve) => {
				app.config.globalProperties.$checkForm(app.config.globalProperties.$cartFormId).then(isValid => {
					if(!isValid)
						alert(app.config.globalProperties.$trans('messages.formIsNotValid'))
					else{
						let dataObject = app.config.globalProperties.$formDataObject(app.config.globalProperties.$cartFormId),
							cart = app.config.globalProperties.$cart.value;

						dataObject.append('cart', JSON.stringify(cart))
						dataObject.append('stripePaymentIntent', app.config.globalProperties.$paymentIntentId.value)
						dataObject.append('school_id', app.config.globalProperties.$schoolID)

						// TODO: Append better Consents
						dataObject.append('accepts_terms', 'true')
						dataObject.append('data_collection', 'data_collection')
						dataObject.append('schools['+app.config.globalProperties.$schoolID+'][consents][data_collection][consent_type]', 'data_collection')
						dataObject.append('schools['+app.config.globalProperties.$schoolID+'][consents][data_collection][record_updated]', 'true')
						dataObject.append('schools['+app.config.globalProperties.$schoolID+'][consents][data_collection][has_given_consent]', 'true')


						if(app.config.globalProperties.$loggedUser.value !== null){
							dataObject.append('person_id', app.config.globalProperties.$loggedUser.value.person_id);
							['first_name', 'last_name', 'email', 'phone'].forEach(item => {
								dataObject.append(item, app.config.globalProperties.$loggedUser.value[item])
							});
						}
						/* Will load directly from form
						else if(app.config.globalProperties.$checkoutAsNewUserInfo.value !== null){
							['first_name', 'last_name', 'email', 'phone', 'password', 'password_confirmation'].forEach(item => {
								dataObject.append(item, app.config.globalProperties.$checkoutAsNewUserInfo.value[item.replace('_confirmation', '')])
							});
						}
						 */

						app.config.globalProperties.$requestPOSTFormData('complete-checkout', dataObject).then(r => {
							app.config.globalProperties.$lastCartMessage.value = r.data;
							if(typeof r.data.booking_request_id === 'undefined')
								alert(app.config.globalProperties.$trans('messages.genericError'));
							else
								app.config.globalProperties.$orderID.value = r.data.booking_request_id[0];

							resolve('completed')
						})
					}
				});
			})
		}

		// Cart Utilities
		app.config.globalProperties.$saveCart = () => {
			app.config.globalProperties.$saveInStorage(last_cart_save, JSON.stringify(new Date()));

			if(JSON.stringify(app.config.globalProperties.$cart.value) === JSON.stringify(empty_cart)){
				app.config.globalProperties.$goToCart.value = false
				return app.config.globalProperties.$removeFromStorage(cart_key)
			}
			else
				return app.config.globalProperties.$saveInStorage(cart_key, JSON.stringify(app.config.globalProperties.$cart.value))
		}

		app.config.globalProperties.$checkIfInCart = (object) => {
			return checkIfInCart(object, app.config.globalProperties.$cart.value)
		}

	}
}

function reportError(response){
	throw Error('Cart request was not successful! '+JSON.stringify(response.data));
}

function checkIfInCart(object, cart){
	return new Promise((resolve, reject) => {
		let category = objectCategory(object),
			response = {
				category: category,
				in_cart: false,
				can_be_pushed: true
			};

		if(category === 'lessons')
			cart[category].forEach((availability, index) => {
				if(availability.id == object.id){
					response.in_cart = true;
					response.index = index;
					response.can_be_pushed = false;
					resolve(response);
				}
			});
		else if(category === 'lessons_no_teacher')
			cart[category].forEach((slot, index) => {
				if(slot.id == object.id){
					response.in_cart = true;
					response.index = index;
					response.can_be_pushed = false;
					resolve(response);
				}
			})
		else if(category === 'giftcards'){
			response.can_be_added = true;
			cart[category].forEach((card, index) => {
				if(card.id == object.id){
					response.in_cart = true;
					response.index = index;
					response.can_be_pushed = false;
					resolve(response);
				}

			});
		}
		else if(category === 'packets' || category === 'events'){
			response.can_be_added = true;
			cart[category].forEach((packet, index) => {
				if(packet.id == object.id){
					response.in_cart = true;
					response.index = index;
					response.can_be_pushed = false;
					resolve(response);
				}
			})
		}
		else if(category.includes('rentals')){
			response.can_be_added = true;
			cart[category].forEach((item, index) => {
				if(item.id == object.id){
					response.in_cart = true;
					response.index = index;
					response.can_be_pushed = false;
					resolve(response);
				}
			})
		}
		else if(category.includes('service')){
			response.can_be_added = object.price_type === 'value';
			cart[category].forEach((service, index) => {
				if(service.id == object.id){
					response.in_cart = true;
					response.index = index;
					response.can_be_pushed = false;
					if(typeof service.price_total !== 'undefined')
						reject('This use case is not supported: cannot add to a service with a specified price');
					resolve(response);
				}
			});
		}
		else
			reject('Category not supported yet');

		resolve(response);
	});
}

function objectCategory(object){
	if(typeof object.association_id !== 'undefined' && object.activity_id !== 'undefined')
		return 'lessons';
	else if(typeof object.capacity !== 'undefined')
		return 'rentals';
	else if(typeof object.type !== 'undefined')
		return object.type+'s';
	else if(typeof object.available_teachers !== 'undefined')
		return 'lessons_no_teacher';
	else if(typeof object.value !== 'undefined' && typeof object.price !== 'undefined')
		return 'giftcards';
	else if(typeof object.apply_to !== 'undefined'){
		if(!object['applies_to_'+object.apply_to.replace('_no_teacher', '')]){
			throw Error('This service does not apply to '+object.apply_to);
		}
		return [object.apply_to, 'services'].join('_');
	}
	else{
		console.log(object);
		throw Error('Object passed not recognized. HINT: When you add or remove a service, you must declare what category is applied to');
	}
}

function mapLessons(availabilities_array){
	console.error('Method not defined');
	return availabilities_array;
}
function mapLessonsNoTeacher(slots_array, app){
	return slots_array.sort(function(a, b){
		return app.config.globalProperties.$momentItem(a.start_time).isAfter(app.config.globalProperties.$momentItem(b.start_time)) ? 1 : -1;
	}).reduce((acc, slot_by_reference) => {

		// WARNING: This prevents the modification of the slot we have in the cart
		let slot = JSON.parse(JSON.stringify(slot_by_reference));

		if(acc.length === 0 || !app.config.globalProperties.$slotsAreConsecutive(acc[acc.length - 1], slot)){
			slot.slot_id = slot.id.toString();
			slot.duration = app.config.globalProperties.$differenceInMinutes(slot.start_time, slot.end_time);
			acc.push(slot);
		}
		else{
			acc[acc.length - 1].end_time = slot.end_time;
			acc[acc.length - 1].duration = app.config.globalProperties.$differenceInMinutes(acc[acc.length - 1].start_time, slot.end_time);
			acc[acc.length - 1].price += parseFloat(slot.price);
			acc[acc.length - 1].price_original += parseFloat(slot.price_original);
			acc[acc.length - 1].slot_id += ','+slot.id.toString();

			if(typeof acc[acc.length - 1].teachers_available !== 'undefined' && typeof slot.teachers_available !== 'undefined'){
				if(slot.teachers_available.length === 0)
					acc[acc.length - 1].teachers_available = [];
				else if(acc[acc.length - 1].teachers_available.length){
					let available_ids = slot.teachers_available.map(association => { return association.id; });
					acc[acc.length - 1].teachers_available = acc[acc.length - 1].teachers_available.filter(el => { return available_ids.includes(el.id); });
				}
			}
		}

		return acc;
	}, []);
}