<template>
	<div class="text-left">
		<p v-if="!$goToPayment.value" class="text-danger">Il tuo carrello non sembra prenotabile, perfavore contattaci</p>
		<template v-else>
			<h4>Completa la tua prenotazione</h4>
			<p>Inserisci tutti i dati di pagamento</p>

			<template v-if="$advancePaymentIsAllowed.value">
				<h4>Come vuoi pagare?</h4>
				<Radio name="payment_type" :values="$paymentTypes.value" @changeRadio="$paymentType.value = $event"></Radio>
				<em>{{ $trans('explanations.paymentTypeTwice', [['deposit', $depositAmountFormatted]]) }}</em>
			</template>

			<h4>Seleziona il metodo di pagamento</h4>
			<Radio name="payment_method" :values="$paymentMethods.value" @changeRadio="$paymentMethod.value = $event"></Radio>

			<template v-if="$paymentIntentId.value !== null">
				<div class="d-grid my-2 py-5">
					<button class="btn btn-primary" @click="this.$completeCheckout">
					{{ $trans('messages.submit') }}
					</button>
				</div>
			</template>
			<StripeCheckout v-else-if="$paymentMethod.value === 'credit_card'"></StripeCheckout>

		</template>

	</div>
</template>

<script>

import Radio from "./_partials/Radio";
import StripeCheckout from "./StripeCheckout";
export default {
	name: 'SelectPaymentMethod',
	components: {StripeCheckout, Radio},
	props:{},
	data() {},
	watch:{
	},
	created(){
	},
	computed:{},
	methods:{
	}
}
</script>