<style lang="scss">
#improoving-widget{
	.product-box-container-outer{
		.go-to-dates{
			display: flex;
			justify-content: space-between;
			font-weight: bold;
			color: white;
			padding: 0;
			border-radius: 5px;
			background-color: orange;

			&.available{ background-color: rgba(87, 135, 126, 1); }
			&.last-places{ background-color: orange; }
			&.fully-booked{ background-color: red; }

			.icon{
				margin-right: 0 !important;
				padding-left: 5px;
				padding-right: 5px;
				background-color: rgba(239, 149, 96, 1);
			}
		}

		.price > *{
			padding-left: 2px;
			padding-right: 2px;
			text-transform: lowercase;
		}
		.price > *:last-child{
			font-weight: bold;
		}

		.product-box-container{
			filter: drop-shadow(0px 4px 4px #242A5C);
			margin: 10px 0;

			.product-box{
				background: rgba(255, 251, 245, 1);
				border-radius: 3px;
				position: relative;
				padding: 5px 15px;
				/* https://bennettfeely.com/clippy/ */
				clip-path: polygon(0 0, 70% 0, 73% 10%, 100% 10%, 100% 100%, 3% 100%, 0 90%);
				cursor: pointer;

				h3{ font-size: 1.5em; }

				&.blue{
					background: rgba(99, 157, 180, 1);
					.badge{ background: rgba(151, 71, 255, 1) !important; }
				}
				&.white{
					background: rgba(216, 217, 203, 1);
					.badge{ background: rgba(45, 167, 154, 1) !important; }
				}
				&.green{
					background: rgba(157, 182, 160, 1);
					.badge{ background: rgba(227, 130, 126, 1) !important; }
				}

				.badge.show-in-specific-spot{
					position: absolute;
					top: 15px;
					right: 5px;
				}

				.location{
					border-bottom: 1px solid black;
					padding-left: 5px;
					padding-right: 15px;
				}
			}
		}

	}
	.product-box .location,
	.product-box-container-outer .price{
		display: flex;
		align-items: baseline;
	}

	.product-box-container-outer .go-to-dates > * ,
	.product-box .location > * {
		margin-left: 5px;
		margin-right: 5px;
	}
}
</style>

<template>
	<div class="product-box-container-outer">
		<div class="product-box-container" @click="handleClick">
			<div :class="`product-box ${classes || ''}`">
				<h3 v-if="showTitle && title !== null" class="d-inline-block mb-0 font-inter font-inter-700">{{ title }}</h3>
				<template v-if="nights !== null && nights > 0">
					<div v-if="isSmallBox" class="badge badge-sm show-in-specific-spot">{{ $transChoice('messages.night', nights, [['night', nights]]) }}</div>
					<div v-else class="d-flex justify-content-between my-1">
						<div class="badge">{{ $transChoice('messages.night', nights, [['night', nights]]) }}</div>
					</div>
				</template>
				<template v-else-if="isCategory">
					<div v-if="isSmallBox" class="badge badge-sm show-in-specific-spot">{{ $transChoice('messages.evergreen', 1) }}</div>
					<div v-else class="d-flex justify-content-between my-1">
						<div class="badge">{{ $transChoice('messages.evergreen', 1) }}</div>
					</div>
				</template>
				<template v-else-if="isGiftCard">
					<div v-if="isSmallBox" class="badge badge-sm show-in-specific-spot">{{ $transChoice('messages.giftCard', 1) }}</div>
					<div v-else class="d-flex justify-content-between my-1">
						<div class="badge">{{ $transChoice('messages.giftCard', 1) }}</div>
					</div>
				</template>
				<template v-else-if="isShortcut">
					<div v-if="isSmallBox" class="badge badge-sm show-in-specific-spot">{{ $transChoice('messages.'+this.jumpToSection.section, 1) }}</div>
					<div v-else class="d-flex justify-content-between my-1">
						<div class="badge">{{ $transChoice('messages.'+this.jumpToSection.section, 1) }}</div>
					</div>
				</template>
				<div class="d-flex justify-content-between align-items-baseline pt-2">
					<div v-if="location !== null" class="location">
						<Icon icon="geo-alt-fill"></Icon>
						{{ location }}
					</div>
					<div v-if="price !== null" class="price">
						<span>{{ $trans('messages.from') }} &euro;</span>
						<h2>{{ price }}</h2>
					</div>
				</div>
			</div>
		</div>
		<ul v-if="show_variations && allowedVariations.length"
			class="scrollmenu">
			<li v-for="variation in allowedVariations"
				:key="['box', packet.id, variation.id].join('.')"
				@click="selectPacketVariation(variation)">
				<template v-if="$momentItem(variation.end_date).diff($momentItem(variation.start_date), 'days') > 0">
					<h2>{{ $momentItemFormattedInterval(variation.start_date, variation.end_date, 'DD') }}</h2>
					<small>{{ $momentItemFormattedInterval(variation.start_date, variation.end_date, 'ddd') }}</small>
				</template>
				<template v-else>
					<h2>{{ $momentItemFormatted(variation.start_date, 'DD') }}</h2>
					<small>{{ $momentItemFormatted(variation.start_date, 'ddd') }}</small>
				</template>
				<p class="days">{{ $momentItem(variation.start_date).isSame($momentItem(variation.end_date), 'month') ? $momentItemFormatted(variation.start_date, 'MMMM') : $momentItemFormattedInterval(variation.start_date, variation.end_date, 'MMM') }}</p>
				<div v-if="price !== null" class="price justify-content-center">
					<span>{{ $trans('messages.from') }} &euro;</span>
					<h2>{{ price }}</h2>
				</div>
				<div v-if="variation.available_places <= 0" class="go-to-dates fully-booked">
					<span>{{ $trans('marketing.bookNowFullyBooked') }}</span>
					<div class="icon"><Icon icon="arrow-right"></Icon></div>
				</div>
				<div v-else-if="variation.available_places <= 5" class="go-to-dates last-places">
					<span>{{ $trans('marketing.bookNowLastPlaces') }}</span>
					<div class="icon"><Icon icon="arrow-right"></Icon></div>
				</div>
				<div v-else class="go-to-dates available">
					<span>{{ $trans('marketing.bookNow') }}</span>
					<div class="icon"><Icon icon="arrow-right"></Icon></div>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
import Icon from "./Icon";

export default{
	name: 'Box',
	components: {Icon},
	inject: ['schoolData'],
	props: {
		packet: {
			type: Object,
			validator(value){
				return typeof value.name === 'string'
					&& typeof value.price !== 'undefined';
			},
			default: null
		},
		category: {
			type: Object,
			validator(value){
				return typeof value.name === 'string'
					&& typeof value.specialization === 'string';
			},
			default: null
		},
		giftCard: {
			type: Object,
			validator(value){
				return typeof value.name === 'string'
					&& typeof value.price === 'string';
			},
			default: null
		},
		jumpToSection: {
			type: String,
			validator(value){
				return typeof value.section === 'string';
			},
			default: null
		},
		classes: {
			type: String,
			default: null
		},
		layout: {
			type: String,
			default: null
		},
		allowedMonth: {
			type: String,
			default: null,
			validator(value){
				return value.match(/^\d{2}([./-])\d{2}\1\d{4}$/);
			}
		},
		startOpen: {
			type: Boolean,
			default: false
		},
		showTitle: {
			type: Boolean,
			default: true
		},
		showStartedProducts: {
			type: Boolean,
			default: false
		}
	},
	data(){
		return {
			show_variations: this.startOpen
		}
	},
	computed: {
		isSmallBox(){
			return this.layout === 'small';
		},
		isPacket(){
			return this.packet !== null;
		},
		isEvent(){
			return this.isPacket && this.packet.type === 'event';
		},
		isCategory(){
			return this.category !== null;
		},
		isGiftCard(){
			return this.giftCard !== null;
		},
		isShortcut(){
			return this.jumpToSection !== null;
		},
		title(){
			return this.isPacket ? this.packet.name :
				(this.isCategory ? this.$trans('translations.'+this.category.specialization) :
					(this.isGiftCard ? this.giftCard.name : this.$transChoice('messages.'+this.jumpToSection.section, 2)));
		},
		nights(){
			return this.isEvent ? this.$momentItem(this.packet.end_date).diff(this.$momentItem(this.packet.start_date), 'days') : null;
		},
		price(){
			return this.isPacket ? parseFloat(Math.min(...this.packet.packet_prices.reduce((acc, price_variation) => {acc.push(parseFloat(price_variation.price)); return acc; }, [parseFloat(this.packet.price)]))).toFixed(0) :
				(this.isGiftCard ? parseFloat(this.giftCard.price).toFixed(0) : null);
		},
		location(){
			// TODO: check this is fine
			return this.isPacket && typeof this.packet.district_id !== 'undefined' && this.packet.district_id !== null ? this.schoolData.districts[this.packet.district_id].name :
				(this.isGiftCard ? null : this.schoolData.city);
		},
		allowedVariations(){
			let valid_date = this.allowedMonth !== null ? this.$momentItem(this.allowedMonth) : null;
			return this.isPacket ? [this.packet].concat(typeof this.packet.variations !== 'undefined' ? this.packet.variations : []).filter(variation => {
				variation.available_places = variation.max_people - variation.pupils.length;
				return (this.showStartedProducts || this.$momentItem(variation.start_date).isSameOrAfter(this.$momentItem(new Date())))
					&& (
						valid_date === null
						|| (
							this.$momentItem(variation.start_date).isSame(valid_date, 'year')
							&& this.$momentItem(variation.start_date).isSame(valid_date, 'month')
						));
			}) : [];
		}
	},
	methods: {
		handleClick(){
			return this.isPacket ? this.showVariations() :
				(this.isCategory ? this.$emit('selectCategory', this.category) :
					(this.isGiftCard ? this.$emit('selectGiftCard', this.giftCard) : this.$emit('selectSection', this.jumpToSection.section)));
		},
		showVariations(){
			if(typeof this.packet.variations !== 'undefined'){
				this.show_variations = !this.show_variations
				return this.$emit('showVariations', this.show_variations)
			}
		},
		selectPacketVariation(variation){
			this.$emit('selectPacket', variation);
		}
	}
}
</script>