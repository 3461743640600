<template>
	<div class="flow-giftcards">
		<template v-if="selectedGiftCard !== null">
			<Product :product="selectedGiftCard"
				@resetSelection="selectedGiftCard = null"></Product>
		</template>
		<template v-else>
			<span @click="returnToWidget"><Icon icon="arrow-left-circle mr-1 improoving-text"></Icon> {{ $trans('messages.back') }}</span>

			<GiftCards @selectGiftCard="selectProduct"></GiftCards>

		</template>
	</div>
</template>

<script>
import CheckoutMixin from "../mixins/checkout_steps"
import GiftCards from "./GiftCards";
import Icon from "./_partials/Icon";
import Product from "./Product";

export default {
	name: 'FlowGiftCards',
	mixins: [CheckoutMixin],
	components: {Product, GiftCards, Icon},
	props: {},
	data(){
		return {
			selectedGiftCard: null,
		}
	},
	computed: {
	},
	created(){
		document.addEventListener(['select', 'giftCard'].join('-'), (e) => {
			console.log("Received the following giftCard", e.detail.giftCard);
			this.selectProduct(JSON.parse(e.detail.giftCard));
		});
	},
	methods: {
		selectProduct(giftCard){
			this.selectedGiftCard = giftCard
		}
	}
}
</script>