<template>
	<div class="teachers">
		<loading v-if="loading"></loading>
		<template v-else>
			<h2 @click="selectSection">{{ $transChoice(['messages', 'teacher'].join('.'), 2) }}</h2>
			<ul v-if="availableTeachers.length > 0" class="list-group">
				<li v-for="teacher in availableTeachers"
					:key="['teacher', teacher.id].join('.')"
					@click="selectTeacher(teacher)"
					:class="`list-group-item ${selectedTeacher !== null && selectedTeacher.id === teacher.id ? 'list-group-item-success' : ''}`">
					<img :src="teacher.person.picture.url" class="teacher-picture" /> {{ $personIdentifier(teacher.person) }}
				</li>
			</ul>
		</template>
	</div>
</template>

<script>
import Loading from "./Loading";
export default {
	name: 'Teachers',
	components: {Loading},
	props: {},
	inject: ['schoolId', 'category_id', 'selectableAssociationIds', 'teachers', 'selectedTeacher'],
	data(){
		return {
			loading: false
		}
	},
	created(){
		document.addEventListener(['show', 'teacher'].join('-'), (e) => {
			if(typeof this.teachers[e.detail.productId] !== 'undefined')
				this.selectPacket(this.teachers[e.detail.productId])
			else{
				// alert(this.$trans('productNotFound'))
			}
		});
	},
	computed: {
		availableTeachers(){
			return Object.values(this.teachers).reduce((acc, teacher) => {
				if(
					this.selectableAssociationIds.includes(teacher.id)
					/* && (this.category_id === null || (typeof teacher.specializations !== 'undefined' && teacher.specializations.length && typeof teacher.specializations.find(el => el.category_id == this.category_id) !== 'undefined')) */
				)
					acc.push(teacher)

				return acc;
			}, []);
		}
	},
	methods: {
		selectSection(){
			this.$emit('selectSection', 'teacher')
		},
		selectTeacher(teacher){
			this.$emit('selectTeacher', teacher)
		}
	}
}
</script>

<style>
#improoving-widget .teacher-picture{
	width: 50px;
	height: auto;
	border-radius: 5px;
	margin: 0 10px 0 0;
}
</style>