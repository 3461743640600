import _ from 'lodash'
import { ref, computed } from 'vue'
import moment from "moment/moment";

let improoving_translations = ref({
	it: {},
	en: {},
	fr: {},
});
let translations = {
	it: {
		welcome: "Benvenuto",
		packet: "Corso|Corsi",
		event: "Esperienza|Esperienze",
		teacher: "Maestro|Maestri",
		no_packet: "Non ci sono Corsi in Programma",
		no_event: "Non ci sono Eventi in Programma",
		no_teacher: "Non ci sono Maestri disponibili",
		productNotFound: "Il prodotto che stavi cercando non è stato trovato",
		sectionNotFound: "La categoria che stavi cercando non è stata trovato",
		return_to_shopping: "Torna agli acquisti",
		go_to_cart: "Vai al checkout",
		select_dates: "Quali date ti interessano?",
		select_dates_explanations: "Seleziona il periodo per cui stai cercando",
		select_dates_selected: "La tua selezione",
		select_dates_change_selection: "Cambia le date",
		multiple_starting_times: ":times periodi disponibili",
		last_places_available: ":places posti disponibili!",
	}
};

export default {
	install: (app, language) => {
		app.config.globalProperties.$language = ref(language);
		updatePlugins(language);

		if(Object.keys(improoving_translations.value[language]).length === 0)
			app.config.globalProperties.$requestGET(['translations', language].join('/')).then(r => { improoving_translations.value[language] = r.data })

		app.config.globalProperties.$changeLanguage = (key) => {
			if(Object.keys(improoving_translations.value[key]).length === 0)
				app.config.globalProperties.$requestGET(['translations', key].join('/')).then(r => { improoving_translations.value[key] = r.data })

			app.config.globalProperties.$language.value = key;
			updatePlugins(key);
		}
		app.config.globalProperties.$trans = (key, options = []) => {
			return replaceInTrans(findTranslation(key, app.config.globalProperties.$language.value).value, options)
		}
		app.config.globalProperties.$transChoice = (key, index, options = []) => {
			let translation = findTranslation(key, app.config.globalProperties.$language.value).value;
			if(typeof translation === 'string'){
				let splits = translation.split('|');
				return replaceInTrans(splits[Math.max(0, Math.min(index - 1, splits.length - 1))], options);
			}
			else if(typeof translation === 'object')
				return replaceInTrans(translation[index], options);
			else{
				return translation;
			}
		}

		app.config.globalProperties.$localizedAttribute = (object, key) => {
			let localized_key = [key, app.config.globalProperties.$language.value].join('_');
			if(typeof object[localized_key] !== 'undefined' && object[localized_key] !== null && object[localized_key].trim() !== '')
				return object[localized_key]
			else if(typeof object[key] !== 'undefined')
				return object[key]
			else
				return key

		}

		app.config.globalProperties.$packetPriceName = (packetPrice) => {
			let trans = app.config.globalProperties.$transChoice('messages.day', packetPrice.days_number);
			if(packetPrice.consecutive)
				trans += ' ' + app.config.globalProperties.$transChoice('messages.consecutive', 2);

			return [packetPrice.days_number, trans].join(' ')
		}

		app.config.globalProperties.$serviceName = (service) => {
			try{
				return service.refund_code !== null ?
					app.config.globalProperties.$trans('marketing.refundsPlan'+service.name) : service.name;
			}
			catch(err){
				console.warn('Could not provide a name for this service', service);
			}
		}
	}
}

function findTranslation(key, language){
	return computed(() => {
		try{
			if(key.includes('.')){
				let parts = key.split('.');
				return improoving_translations.value[language][parts[0]][parts[1]] || key;
			}
			else
				return _.get(translations[language], key, key) || key;
		}
		catch(error){
			return key;
		}
	});
}

function replaceInTrans(trans, options = []){
	options.forEach(element => {
		try{
			trans = trans.replace(new RegExp(':'+element[0], 'g'), element[1]);
		}
		catch(err){
			console.warn(trans);
		}

	});
	return trans;
}

function updatePlugins(lang_key){
	moment.updateLocale(lang_key, {
		ordinal : Function
	});
}