<style>
#improoving-widget label > input[type="checkbox"]{
	margin-right: 5px;
}
</style>

<template>
	<div :class="divClasses">
		<label :class="labelClass">
			<input v-if="disabled"
					type="checkbox"
					autocomplete="off"
					:id="id"
					:name="name"
					:value="value"
					:class="checkboxClass"
					v-model="checkedModel" disabled />
			<input type="checkbox"
					autocomplete="off"
					:id="id"
					:name="name"
					:value="value"
					:class="checkboxClass"
					v-model="checkedModel" v-else/>
			<div v-if="trimmedLabel !== ''"
				class="ml-05"
				v-html="trimmedLabel"></div>
			<div class="ml-05" v-else>
				<slot></slot>
			</div>
		</label>
	</div>
</template>
<script>
export default {
	name: 'Checkbox',
	props: {
		id: {
			type: String,
			default: null
		},
		value: {
			type: String,
			required: true
		},
		checked: {
			type: Boolean,
			default: false
		},
		name: {
			type: String,
			required: true
		},
		checkboxClasses: {
			type: String,
			default: null
		},
		classes: {
			type: String,
			default: null
		},
		classesLabel: {
			type: String,
			default: null
		},
		disabled: {
			type: Boolean,
			default: false
		},
		text: {
			type: String,
			default: ''
		},
	},
	data(){
		return {
			checkedModel: this.checked === true
		}
	},
	computed: {
		checkboxClass(){
			let classes = [
				'form-check-input pointer',
				this.checkboxClasses,
				'position-static'
			];
			return classes.join(' ');
		},
		divClasses(){
			let classes = ['form-check checkbox', this.classes];
			return classes.join(' ');
		},
		labelClass(){
			let classes = ['form-check-label pointer d-flex', this.classesLabel];
			return classes.join(' ');
		},
		trimmedLabel(){
			return this.text;
		},
	},
	watch: {
		checked(newValue){
			this.checkedModel = newValue === 'true' || newValue === true;
		},
		checkedModel(newValue){
			let _this = this;
			_this.$nextTick(function(){
				_this.$emit('change', newValue);

				if(newValue)
					_this.$emit('changeChecked');
				else
					_this.$emit('changeNotChecked');
			});
		}
	},
	methods: {}
}
</script>